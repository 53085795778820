import { Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';

import CarDetailsModal from '../../../../../components/CarDetailsModal';
import {
  TemporaryData,
  TemporaryInfo,
  useTemporaryInfoUpdateMutation,
} from '../../../../../gql/generated/graphql';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
} from '../../../../../utils/validation/licenseNumberValidator';
import validateVin from '../../../../../utils/validation/vinValidator';

interface Props {
  info?: TemporaryInfo;
  isOpen: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}

const LetsFixThat = ({ info, isOpen, setOpenModal, setRefresh }: Props) => {
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const handleSubmit = async (values: TemporaryData) => {
    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            vin: values.vin,
            year: values?.year?.toString(),
            make: values.make,
            model: values.model,
            license_plate_state: values.license_plate_state,
            license_plate_number: values.license_plate_number,
            fuel_type: values.fuel_type,
            vehicle_type: values.vehicle_type,
            kbb_trim_name: values.kbb_trim_name,
            manual_entry: true,
          },
        },
      },
    });

    setRefresh((prevValue) => !prevValue);
    setOpenModal(!isOpen);
  };

  const isManualEntry = info?.data?.manual_entry ?? false;

  const validationSchema = Yup.object({
    license_plate_number: isManualEntry
      ? Yup.string()
          .required('Please enter a value')
          .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value) =>
            validateLicenseNumber({ licenseNumber: value }),
          )
      : Yup.string(),
    license_plate_state: isManualEntry
      ? Yup.string().required('Please enter a value')
      : Yup.string(),
    vin: Yup.string()
      .required('Please enter a value')
      .test('is-valid-vin', 'Invalid VIN', (value) => validateVin(value)),
    year: Yup.string().required('Please enter a value'),
    make: Yup.string().required('Please enter a value'),
    model: Yup.string().required('Please enter a value'),
    fuel_type: isManualEntry ? Yup.string().required('Please select a value') : Yup.string(),
    vehicle_type: isManualEntry ? Yup.string().required('Please select a value') : Yup.string(),
    kbb_trim_name: Yup.string(),
  });

  const initialValues = {
    license_plate_number: info?.data?.license_plate_number ?? '',
    license_plate_state: info?.data?.state ?? info?.data?.license_plate_state ?? '',
    vin: info?.data?.vin ?? '',
    year: info?.data?.year ?? '',
    make: info?.data?.make?.toUpperCase() ?? '',
    model: info?.data?.model ?? '',
    fuel_type: info?.data?.fuel_type ?? '',
    vehicle_type: info?.data?.vehicle_type ?? '',
    kbb_trim_name: info?.data?.kbb_trim_name ?? '',
  };

  return (
    <CarDetailsModal
      title="Okay, let's fix that"
      subtitle={
        <>
          Double-check that your <Text as="b">License Plate</Text> and <Text as="b">State</Text> are
          correct. If it is, go ahead and change the other information below to match your vehicle.
        </>
      }
      isOpen={isOpen}
      setIsOpen={setOpenModal}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      isManualEntry={isManualEntry}
    />
  );
};

export default LetsFixThat;
