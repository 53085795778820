import { Maybe } from '../../gql/generated/graphql';

export const replaceUnderscores = (str: string, replacement = ' ') => {
  return str.replace(/_/g, replacement);
};

export const capitalizeFirstLetter = (str: Maybe<string>) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
};

export const formatPhoneNumber = (phoneNumberString: Maybe<string>) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};
