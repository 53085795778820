import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTemporaryInfoUpdateMutation } from '../../gql/generated/graphql';
import { useTempInfo } from '../../hooks/useTempInfo';
import { camelCaseToSpaceSeparated } from '../../utils/helpers';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import LEAccordion from './LEAccordion';
import { ACCORDION_NAME, ACCORDION_NAME_TO_INDEX, InternalItemType } from './utils';

type UseLEAccordionOpts = {
  useTracking?: boolean;
  rightSidebar?: React.ReactNode;
  name: ACCORDION_NAME;
};

/**
 * Internal use only for LEAccordion
 *
 * Controls the state of LEAccordion
 */
export const useLEAccordion = ({ useTracking, rightSidebar, name }: UseLEAccordionOpts) => {
  const { info } = useTempInfo('network-only');
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const [itemStates, setItemStates] = useState<{ [key: string]: InternalItemType }>({});

  const updateItemState = (key: string, itemState: Partial<InternalItemType>) =>
    setItemStates((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...itemState },
    }));

  const itemIndexes = useMemo(
    () => Object.fromEntries(Object.values(itemStates).map((item) => [item.index, item])),
    [itemStates],
  );

  const isInitialized = useMemo(
    () => Object.values(itemStates).every((item) => item.index !== -1),
    [itemStates],
  );

  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
  const [currentAccordionAndPanel, setCurrentAccordionAndPanel] = useState(
    info?.data?.current_accordion_panel,
  );

  useEffect(() => {
    if (info?.data?.current_accordion_panel) {
      setCurrentAccordionAndPanel(info.data.current_accordion_panel);
    }
  }, [info?.data?.current_accordion_panel]);

  useEffect(() => {
    const [accordionIndex, panelIndex] = currentAccordionAndPanel?.split(':') ?? [];
    const currentAccordionIndex = ACCORDION_NAME_TO_INDEX[name];

    if (currentAccordionIndex < Number(accordionIndex)) {
      setItemStates((prev) => ({
        ...prev,
        ...Object.fromEntries(
          Object.values(prev).map((item) => [item.key, { ...item, status: 'complete' }]),
        ),
      }));
      const indexList = Object.keys(itemIndexes);
      setOpenAccordionIndex(Number(indexList[indexList.length - 1]));

      return;
    }

    if (currentAccordionIndex === Number(accordionIndex)) {
      // If the saved index is greater than the current number of indices, don't do anything
      // (If the saved panelIndex is 2 but the number of panels shrinks to smaller than that during development of COM)
      const indexList = Object.keys(itemIndexes);
      if (indexList[Number(panelIndex)]) {
        setItemStates((prev) => ({
          ...prev,
          ...Object.fromEntries(
            Object.values(prev).map((item) =>
              item.index < Number(panelIndex)
                ? [item.key, { ...item, status: 'complete' }]
                : [item.key, { ...item }],
            ),
          ),
        }));
        setOpenAccordionIndex(Number(panelIndex));
      }
    }
  }, [currentAccordionAndPanel]);

  useEffect(() => {
    if (
      isInitialized &&
      openAccordionIndex !== -1 &&
      useTracking &&
      itemIndexes[openAccordionIndex]?.key
    ) {
      const [accordionIndex, panelIndex] = currentAccordionAndPanel?.split(':') ?? [];
      const isNewPanel =
        ACCORDION_NAME_TO_INDEX[name] > Number(accordionIndex) ||
        (ACCORDION_NAME_TO_INDEX[name] === Number(accordionIndex) &&
          openAccordionIndex > Number(panelIndex));

      if (isNewPanel) {
        const currentAccordionPanel = `${ACCORDION_NAME_TO_INDEX[name]}:${openAccordionIndex}`;
        updateTemporaryInfo({
          variables: {
            info: {
              id: info?.id,
              data: {
                current_accordion_panel: currentAccordionPanel,
              },
            },
          },
        });

        setCurrentAccordionAndPanel(currentAccordionPanel);
      }

      rudderanalytics.track(RudderEvent.AccordionView, {
        accordion: name,
        accordion_item: camelCaseToSpaceSeparated(itemIndexes[openAccordionIndex].key),
      });
    }
  }, [isInitialized, openAccordionIndex, currentAccordionAndPanel]);

  const [accordionProgress, setAccordionProgress] = useState(0);
  useEffect(() => {
    let count = 0;
    let total = 0;
    Object.values(itemIndexes).forEach((item) => {
      if (item) total += 1;
      if (item && item.status === 'complete') count += 1;
    });

    setAccordionProgress((count / total) * 100);
  }, [itemIndexes]);

  // Moves backwards if any previous panels are incomplete.
  useEffect(() => {
    if (!itemIndexes?.[0]) {
      return;
    }
    if (openAccordionIndex < 0) {
      setOpenAccordionIndex(0);
      return;
    }
    const currentIndexExists = Object.entries(itemIndexes).some(
      ([index, item]) => item && Number(index) === openAccordionIndex,
    );
    const isPreviousIncomplete = Object.entries(itemIndexes).reduce((prev, [index, item]) => {
      if (Number(index) >= openAccordionIndex || Number(index) < 0) return prev;
      return (item && item.status !== 'complete') || prev;
    }, false);

    if (isPreviousIncomplete || !currentIndexExists) {
      setOpenAccordionIndex(openAccordionIndex - 1);
    }
  }, [itemIndexes, openAccordionIndex]);

  return {
    itemStates,
    itemIndexes,
    updateItemState,
    openAccordionIndex,
    setOpenAccordionIndex,
    accordionProgress,
    rightSidebar,
  };
};

type LEAccordionContextType = ReturnType<typeof useLEAccordion>;

const LEAccordionContext = createContext<LEAccordionContextType>({
  itemStates: {},
  itemIndexes: {},
  updateItemState: () => null,
  openAccordionIndex: 0,
  setOpenAccordionIndex: () => null,
  accordionProgress: 0,
  rightSidebar: undefined,
});

/**
 * Internal use only for useLEAccordionItem
 */
export const useLEAccordionContext = () => {
  const context = useContext(LEAccordionContext);
  if (!context) {
    throw new Error(
      `useLEAccordionContext returned \`undefined\`. Seems you forgot to use ${LEAccordion.name}`,
    );
  }

  return context;
};

export const LEAccordionProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: LEAccordionContextType }>) => {
  return <LEAccordionContext.Provider value={value}>{children}</LEAccordionContext.Provider>;
};
