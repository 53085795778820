import { gql } from '@apollo/client/core';

// Add more deal attributes here if needed
export const externalUpdateDeal = gql`
  mutation externalUpdateDeal($dealId: ID, $tempInfoId: ID, $data: ExternalDealInputType!) {
    externalUpdateDeal(dealId: $dealId, tempInfoId: $tempInfoId, data: $data) {
      id
    }
  }
`;

export const dealAdvisors = gql`
  query dealAdvisors($dealId: ID) {
    dealAdvisors(dealId: $dealId) {
      setter {
        name
      }
      closer {
        name
      }
      funding_clerk {
        name
      }
      title_clerk {
        name
      }
    }
  }
`;

export const totalCompletedDeals = gql`
  query totalCompletedDeals {
    totalCompletedDeals
  }
`;

export const getUnlinkedDeals = gql`
  query getUnlinkedDeals {
    getUnlinkedDeals {
      id
      car {
        year
        make
        model
        vin
        vehicle_type
        color
      }
      isCobuyer
      type
    }
  }
`;
