import {
  Box,
  Button,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Icon,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import Big from 'big.js';
import { Form, Formik } from 'formik';
import { useAtomValue } from 'jotai';
import { FaChevronRight } from 'react-icons/fa';

import { digitallyFoundAtom, helpAtom } from '../..';
import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import LienholderPayoffInstructions from '../../../../components/LienholderPayoffInstructions';
import Loader from '../../../../components/Loader';
import DateInput from '../../../../components/formComponents/DateInput';
import NumberInput from '../../../../components/formComponents/NumberInput';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../../../constants/flags';
import {
  PayoffRequest,
  PayoffRequestStatus,
  TemporaryInfo,
  useCancelPayoffRequestMutation,
  useGetFeesLazyQuery,
  usePayoffRequestQuery,
  useTemporaryInfoQuery,
  useTemporaryInfoUpdateMutation,
} from '../../../../gql/generated/graphql';
import { useFlag } from '../../../../hooks';
import { CookieKeys, useCookie } from '../../../../hooks/useCookie';
import { useStep } from '../../../../hooks/useStep';
import { logger } from '../../../../services/sentry';
import { ONE_DAY_MS, checkLastDayOfMonth } from '../../../../utils/dates';
import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import { createErrorToast } from '../../../../utils/toast';
import { PAYOFF_PAGE_VARIATIONS } from '../../constants';
import PayoffEstimateModal from '../PayoffEstimateModal';
import YellowBox from '../WeCanHelpItem/YellowBox';
import { PayoffValues, getPayoffAndStateTaxes, isPayoffEstimated, validationSchema } from './utils';

const Content = () => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const { index, setOpenAccordionIndex, state, updateState } = useLEAccordionItemContext();
  const step = useStep();
  const toast = useToast();
  const {
    isOpen: isEstimatedModalOpen,
    onClose: onEstimatedModalClose,
    onOpen: onEstimatedModalOpen,
  } = useDisclosure();
  const { isOpen: payoffExplanationOpen, onToggle: payoffExplanationToggle } = useDisclosure();

  const help = useAtomValue(helpAtom);
  const digitallyFound = useAtomValue(digitallyFoundAtom);
  const uploadLeaseAgreementFlag = useFlag(LDFlags.LEASE_AGREEMENT_UPLOAD);
  const includeSalesTaxOnPayoffEnabled = useFlag(LDFlags.COM_INCLUDE_SALES_TAX_ON_PAYOFF);
  const payoffPageVariation = useFlag(LDFlags.PAYOFF_PAGE_VARIATION);

  const [getTTFeesLazyQuery] = useGetFeesLazyQuery();
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const [cancelPayoffRequest] = useCancelPayoffRequestMutation();

  const { data: payoffRequest, loading: payoffRequestLoading } = usePayoffRequestQuery();
  const { data: tempInfo, loading } = useTemporaryInfoQuery({
    variables: { id: guid ?? '' },
  });

  if (loading || !tempInfo?.temporaryInfo?.data || payoffRequestLoading) {
    return <Loader />;
  }

  const tempInfoData = tempInfo.temporaryInfo.data;
  const payoffRequestData = (payoffRequest?.payoffRequest ?? {}) as PayoffRequest;

  const initialValues: PayoffValues = {
    totalPayoff:
      new Big(tempInfoData.vehicle_payoff ?? 0)
        .plus(tempInfoData.sales_tax_from_payoff ?? 0)
        .toNumber() ||
      tempInfoData.user_entered_total_payoff ||
      undefined,
    vehiclePayoff: tempInfoData.vehicle_payoff ?? undefined,
    payoffIncludesSalesTax: tempInfoData.payoff_includes_sales_tax ?? false,
    salesTaxFromPayoff: tempInfoData.sales_tax_from_payoff ?? undefined,
    goodThroughDate: tempInfoData?.good_through_date
      ? new Date(tempInfoData?.good_through_date)
      : undefined,
  };

  const payoffRequestCompleted =
    digitallyFound || (help && payoffRequestData?.status === PayoffRequestStatus.Completed);

  const handleSubmit = async (values: PayoffValues, goodToGo?: boolean) => {
    try {
      updateState({ status: 'loading' });
      const {
        vehiclePayoff,
        salesTaxFromPayoff,
        goodThroughDate,
        payoffIncludesSalesTax,
        totalPayoff,
      } = await getPayoffAndStateTaxes({
        values,
        tempInfoData,
        getTTFeesLazyQuery,
      });

      if (
        !payoffRequestCompleted &&
        !goodToGo &&
        totalPayoff != null &&
        isPayoffEstimated(totalPayoff)
      ) {
        onEstimatedModalOpen();
        updateState({ status: 'warn' });
        return;
      }

      const data = {
        vehicle_payoff: vehiclePayoff,
        sales_tax_from_payoff: salesTaxFromPayoff,
        good_through_date: goodThroughDate,
        payoff_includes_sales_tax: payoffIncludesSalesTax,
        user_entered_total_payoff: totalPayoff,
      };
      await updateTemporaryInfo({
        variables: {
          info: {
            id: tempInfo.temporaryInfo?.id,
            data,
          },
        },
      });

      if (
        !payoffRequestCompleted &&
        (payoffRequestData.status === PayoffRequestStatus.Fetching ||
          payoffRequestData.status === PayoffRequestStatus.Pending ||
          payoffRequestData.status === PayoffRequestStatus.Completed)
      ) {
        cancelPayoffRequest();
      }

      updateState({ status: 'complete' });

      if (payoffRequestCompleted) {
        rudderanalytics.track(RudderEvent.PayoffAutomaticallyRetrieved, {
          lienholder: tempInfoData?.lienholder ?? '',
          payoff_amount: vehiclePayoff,
        });
      }

      if (uploadLeaseAgreementFlag) {
        onEstimatedModalClose();
        setOpenAccordionIndex(index + 1);
      } else {
        step.moveNext();
      }
    } catch (e) {
      logger.error('Content.tsx', 'Failed getting and saving payoffs', values, e);
      updateState({ status: 'error' });
      toast(createErrorToast({ errorMessage: 'Failed getting and saving payoffs' }));
    }
  };

  return (
    <>
      {payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0 && (
        <>
          <VStack fontSize="16px" spacing={1} textAlign="center">
            {payoffRequestCompleted ? (
              <>
                <Text mb={4}>
                  We found your payoff! If you feel this information is inaccurate, please contact
                  us.
                </Text>
              </>
            ) : (
              <>
                <Text as="b">Be sure this value:</Text>
                <Text>
                  <Text as="b">IS NOT</Text> your vehicle's Residual Value
                </Text>
                <Text>
                  <Text as="b">IS</Text> the exact amount provided by your bank
                </Text>
              </>
            )}
          </VStack>
          <Divider color="grayDarkBackground" my={6} />
        </>
      )}
      <Formik<PayoffValues>
        onSubmit={(values) => handleSubmit(values, false)}
        initialValues={initialValues}
        validationSchema={validationSchema(payoffRequestCompleted)}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, errors, setFieldValue }) => (
          <Box as={Form} mx={payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0 ? 8 : 4}>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Flex direction="column" w={{ base: '100%', md: '50%' }} mr={{ base: 0, md: 8 }}>
                <NumberInput
                  type="tel"
                  _input={{ inputMode: 'decimal' }}
                  _container={{ textAlign: 'left' }}
                  label="PAYOFF AMOUNT"
                  name={payoffRequestCompleted ? 'vehiclePayoff' : 'totalPayoff'}
                  placeholder="Payoff Amount"
                  isMoney
                  labelProps={{ alignSelf: { base: 'center', md: 'start' }, mx: 0 }}
                  readOnly={payoffRequestCompleted}
                />
                {!help && includeSalesTaxOnPayoffEnabled && (
                  <Checkbox
                    mt={2}
                    name="payoffIncludesSalesTax"
                    isChecked={values.payoffIncludesSalesTax}
                    onChange={() => {
                      const includesTaxes = !values.payoffIncludesSalesTax;
                      if (includesTaxes) {
                        rudderanalytics.track(RudderEvent.PayoffIncludesSalesTax);
                      }
                      setFieldValue('payoffIncludesSalesTax', includesTaxes);
                    }}
                    readOnly={payoffRequestCompleted}
                  >
                    Includes sales tax
                  </Checkbox>
                )}
              </Flex>
              <Box mt={{ base: 6, md: 0 }} w={{ base: '100%', md: '50%' }} ml={{ base: 0, md: 8 }}>
                <DateInput
                  hideCalendar
                  label="GOOD-THROUGH DATE"
                  name="goodThroughDate"
                  labelProps={{ alignSelf: { base: 'center', md: 'start' }, mx: 0 }}
                  readOnly={payoffRequestCompleted}
                  openToDate={checkLastDayOfMonth() ? new Date(Date.now() + ONE_DAY_MS) : undefined}
                />
              </Box>
            </Flex>
            {payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation1 && (
              <Box>
                <Text mt={4}>
                  Be sure this is <Text as="b">NOT</Text> your vehicle's Residual Value
                  <br />
                  Make sure it <Text as="b">IS the exact amount</Text> provided by{' '}
                  {tempInfoData?.lienholder}
                </Text>
                <Button
                  leftIcon={
                    <Icon
                      transform={payoffExplanationOpen ? 'rotate(90deg)' : 'rotate(0deg)'}
                      transition="transform 0.3s ease"
                      as={FaChevronRight}
                      mr="1"
                    />
                  }
                  variant="link"
                  onClick={payoffExplanationToggle}
                  my={4}
                >
                  What is a Payoff Amount?
                </Button>
                <Collapse in={payoffExplanationOpen} animateOpacity>
                  <YellowBox />
                </Collapse>
                <Divider color="grayDarkBackground" mt={1} mb={4} />
                <Text fontSize="16px" fontWeight="semibold" mb={4} textTransform="uppercase">
                  How to find your Payoff Amount with {tempInfoData?.lienholder}:
                </Text>
                <LienholderPayoffInstructions info={tempInfo?.temporaryInfo as TemporaryInfo} />
              </Box>
            )}
            <Center mt="30px">
              <PrimaryButton
                type="submit"
                loading={state.status === 'loading'}
                disabled={
                  (!values.totalPayoff || !values.goodThroughDate) &&
                  (!!errors.totalPayoff || !!errors.goodThroughDate)
                }
              >
                {uploadLeaseAgreementFlag ||
                payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation1
                  ? 'NEXT'
                  : 'ALL SET!'}
              </PrimaryButton>
            </Center>
            {!payoffRequestCompleted && (
              <PayoffEstimateModal
                values={values}
                handleSubmit={handleSubmit}
                payoffAmount={values.totalPayoff ?? 0}
                onEstimatedModalClose={onEstimatedModalClose}
                isEstimatedModalOpen={isEstimatedModalOpen}
              />
            )}
          </Box>
        )}
      </Formik>
    </>
  );
};

export default Content;
