import { Box, Center, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';

import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import DynamicLinkContent from '../../../components/DynamicLinkContent';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import Modal from '../../../components/Modal';
import TextWithTooltip from '../../../components/TextWithTooltip';
import { Label } from '../../../components/formComponents';
import DateInput from '../../../components/formComponents/DateInput';
import LoanAppSSNInput from '../../../components/formComponents/ssn/LoanAppSsnInput';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../../constants/flags';
import { options } from '../../../constants/maritalStatuses';
import {
  ConsentAgreementTypeEnum,
  StateAbbreviation,
  TtGetFeesSourceType,
  useCurrentConsentAgreementTextQuery,
  useExecuteExternalSoftPullMutation,
  useTemporaryInfoUpdateMutation,
  useVehicleMileageLazyQuery,
} from '../../../gql/generated/graphql';
import { useFlag, useStep, useTempInfo } from '../../../hooks';
import { getCreditScore } from '../../../utils/creditScore';
import { odometerStatus } from '../../../utils/mileageWarnings';
import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';
import { hasCobuyer } from '../../../utils/tempInfo';
import {
  SsnAndBirthdayValues,
  ssnAndBirthdayInitialValues,
  ssnAndBirthdayValidationSchema,
} from '../utils';
import AddCobuyerButton from './AddCobuyerButton';
import { devCreditPrequalOverride, devCreditScoreOverride } from './LoanAppDevToolbar';

export const prequalRanInMktAtom = atom<boolean>(false);

const SsnAndBirthdate = () => {
  const step = useStep();

  const creditPrequalificationEnabled = useFlag(LDFlags.CREDIT_PREQUALIFICATION);
  const useHardPull = useFlag(LDFlags.EXPERIAN_HARD_PULL);
  const devCreditPrequalSuccess = useAtomValue(devCreditPrequalOverride);
  const devCreditScore = useAtomValue(devCreditScoreOverride);
  const setPrequalRanInMktAtom = useSetAtom(prequalRanInMktAtom);

  const [termsModalOpen, setTermsModalOpen] = useState(false);

  const [executeSoftPullPrequalification] = useExecuteExternalSoftPullMutation({
    context: {
      isErrorHandled: true,
    },
  });
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const [getMileageData] = useVehicleMileageLazyQuery();
  const { data: termsCopy, loading: termsLoading } = useCurrentConsentAgreementTextQuery({
    variables: { agreementType: ConsentAgreementTypeEnum.TermsAndConditions },
  });
  const { data: consentAgreement, loading: consentLoading } = useCurrentConsentAgreementTextQuery({
    variables: {
      agreementType: useHardPull
        ? ConsentAgreementTypeEnum.HardPull
        : ConsentAgreementTypeEnum.SoftPull,
      version: useHardPull ? 2 : 1,
    },
    skip: !creditPrequalificationEnabled,
  });

  const { updateState, isExpanded } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const tempInfoData = info?.data;
  const prequalRanInMkt = !!tempInfoData?.prequal_id;

  useEffect(() => {
    setPrequalRanInMktAtom(prequalRanInMkt);
  }, [prequalRanInMkt]);

  const onSubmitForm = async (values: SsnAndBirthdayValues, addCobuyer: boolean) => {
    updateState({ status: 'loading' });

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            dob: values.dob,
            ...(values.must_enter_ssn && { ssn: values.ssn }),
            marital_status: values.marital_status,
            include_cobuyer: addCobuyer,
          },
        },
      },
    });

    rudderanalytics.identify({ birthday: values.dob?.toISOString() });

    if (creditPrequalificationEnabled && !addCobuyer && info?.data) {
      const prequal = await executeSoftPullPrequalification({
        variables: {
          paymentEstimateInput: {
            term: info.data.term ?? 0,
            payoff: info.data.vehicle_payoff ?? 0,
            moneyDown: info.data.down_payment ?? 0,
            creditScore: getCreditScore(devCreditScore ?? info.data.credit_score ?? 0),
            zipCode: info.data.zip as string,
            vin: info.data.vin,
            year: info.data.year,
            make: info.data.make,
            model: info.data.model,
            vehicleType: info.data.vehicle_type,
            fuelType: info.data.fuel_type,
            ttGetFeesSource: TtGetFeesSourceType.ComPrequalification,
          },
          devCreditScore: devCreditScore ?? undefined,
          devOverride: devCreditPrequalSuccess,
          isHardPull: useHardPull,
        },
      });

      const mileageData = await getMileageData({
        variables: { vin: info?.data?.vin as string },
        context: {
          isErrorHandled: true,
        },
      });

      await updateTemporaryInfo({
        variables: {
          info: {
            id: info?.id,
            data: {
              odometer_status: odometerStatus(
                mileageData.data?.vehicleMileage?.lastOdometer,
                mileageData.data?.vehicleMileage?.lastOdometerDate,
                info.data.mileage as number,
              ),
            },
          },
        },
      });

      const passedPrequal = devCreditPrequalSuccess ?? prequal?.data?.executeExternalSoftPull;

      rudderanalytics.track(RudderEvent.CreditSoftPull, {
        prequalification_success: passedPrequal ?? undefined,
      });
    }

    updateState({ status: 'complete' });

    step.moveNext({ hasCobuyer: addCobuyer });
  };

  const fromWisconsin = tempInfoData?.state === StateAbbreviation.Wi;

  if (!tempInfoData) {
    return null;
  }

  return (
    <Box>
      <Center mb={{ base: 4, md: 0 }}>
        <TextWithTooltip label="Providing this information helps us fulfill lender requirements to verify your identity.">
          Why do we need this?
        </TextWithTooltip>
      </Center>
      <Formik
        enableReinitialize
        initialValues={ssnAndBirthdayInitialValues(tempInfoData)}
        validationSchema={ssnAndBirthdayValidationSchema(fromWisconsin)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => onSubmitForm(values, hasCobuyer(tempInfoData) || false)}
      >
        {({ isSubmitting, values, setFieldValue, validateForm, setErrors, setFieldTouched }) => {
          return (
            <Form>
              <Flex
                flexDir={{ base: 'column', md: 'row' }}
                alignItems="flex-start"
                mt={4}
                mx="auto"
                w={{ base: '100%', md: '90%', lg: '95%', xl: '100%' }}
              >
                <DateInput
                  _container={{ mr: { base: 0, md: 2 }, mb: { base: 4, md: 0 } }}
                  hideCalendar
                  label="BIRTHDATE"
                  name="dob"
                />
                <LoanAppSSNInput
                  setMustEnterSsn={(mustEnterSsn: boolean) =>
                    setFieldValue('must_enter_ssn', mustEnterSsn)
                  }
                  tempInfo={tempInfoData}
                />
              </Flex>
              {fromWisconsin && (
                <Box mt={4} mb={10}>
                  <Label mb={2}>MARITAL STATUS</Label>
                  <ButtonedRadioGroup
                    columns={{ base: 2, md: 4 }}
                    value={values.marital_status}
                    onChange={(value: string) => setFieldValue('marital_status', value)}
                    options={options}
                    name="marital_status"
                  />
                </Box>
              )}

              <Flex flexDir="column" alignItems="center" gap={6} mt="30px">
                {((creditPrequalificationEnabled && !prequalRanInMkt) || useHardPull) && (
                  <>
                    {consentLoading ? (
                      <SkeletonText minW="85%" noOfLines={4} />
                    ) : (
                      <DynamicLinkContent
                        content={consentAgreement?.currentConsentAgreementText?.text || ''}
                        onDynamicContentClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setTermsModalOpen(true);
                        }}
                        textAlign="center"
                        color="gray"
                        fontSize="12px"
                      />
                    )}
                  </>
                )}

                <PrimaryButton
                  loading={isSubmitting}
                  isDisabled={isSubmitting || consentLoading || termsLoading}
                  type="submit"
                >
                  {hasCobuyer(tempInfoData) ? 'EDIT CO-BUYER INFO' : 'SUBMIT'}
                </PrimaryButton>
                <AddCobuyerButton
                  isDisabled={isSubmitting || consentLoading || termsLoading}
                  onClick={async () => {
                    setFieldTouched('dob', true);
                    setFieldTouched('ssn', true);
                    const errors = await validateForm(values);
                    if (Object.keys(errors).length > 0) {
                      setErrors(errors);
                      return;
                    }

                    onSubmitForm(values, true);
                  }}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>

      <Modal
        title="Terms &amp; Conditions"
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      >
        <Text>{termsCopy?.currentConsentAgreementText?.text}</Text>
      </Modal>
    </Box>
  );
};

export default SsnAndBirthdate;
