import { Box, Flex, Icon, Text } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';

import { LDFlags } from '../../constants/flags';
import {
  COBUYER_LOAN_APPLICATION,
  GETTING_STARTED,
  LOAN_APPLICATION,
  PAYOFF_INSTRUCTIONS,
} from '../../constants/urls';
import { useFlag } from '../../hooks';

interface Props {
  inFlow?: boolean;
}

enum STEP_STATUS {
  completed = 'completed',
  current = 'current',
  incompleted = 'incompleted',
}

const FlowProgressBar = ({ inFlow }: Props) => {
  const showFlowProgressMeter = useFlag(LDFlags.SHOW_FLOW_PROGRESS_METER);
  const { pathname } = useLocation();

  const activePosition = (url: string) => {
    switch (url) {
      case GETTING_STARTED:
        return 0;
      case PAYOFF_INSTRUCTIONS:
        return 1;
      case LOAN_APPLICATION:
      case COBUYER_LOAN_APPLICATION:
        return 2;
      default:
        return null;
    }
  };
  const currentPosition = activePosition(pathname);

  const STEPS = {
    vehicle: {
      label: 'Vehicle',
      position: 0,
    },
    payoff: {
      label: 'Payoff',
      position: 1,
    },
    loan: {
      label: 'Loan',
      position: 2,
    },
    finalize: {
      label: 'Finalize',
      position: 3,
    },
  };

  const stepStatus = (position: number) => {
    if (currentPosition && position < currentPosition) {
      return STEP_STATUS.completed;
    }

    if (position === currentPosition) {
      return STEP_STATUS.current;
    }

    return STEP_STATUS.incompleted;
  };

  const stepColor = (status: string) => {
    if (status === STEP_STATUS.completed || status === STEP_STATUS.current) {
      return 'oceanBoatBlue';
    }

    return 'taupeGray';
  };

  if (!showFlowProgressMeter || currentPosition === null || !inFlow) {
    return null;
  }

  return (
    <Flex position="absolute" left="50%" transform="translateX(-50%)">
      {Object.entries(STEPS).map(([key, { label, position }], stepIndex) => {
        const status = stepStatus(stepIndex);
        const isCurrentOrCompleted =
          status === STEP_STATUS.current || status === STEP_STATUS.completed;

        return (
          <Flex flexDir="column" alignItems="center" key={key}>
            <Flex
              w="120px"
              minH="50px"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                zIndex={10}
                bgColor={stepColor(status)}
                w={isCurrentOrCompleted ? 6 : 2}
                h={isCurrentOrCompleted ? 6 : 2}
                borderRadius="full"
                mb={1}
              >
                {status === STEP_STATUS.completed && (
                  <Icon as={FaCheck} zIndex={11} color="white" mt={0.5} />
                )}
                {status === STEP_STATUS.current && (
                  <Box zIndex={11} bgColor="white" rounded="full" w={2} h={2} />
                )}
              </Flex>
              {stepIndex !== Object.entries(STEPS).length - 1 && (
                <Box
                  zIndex={1}
                  position="absolute"
                  w="120px"
                  h={stepIndex < currentPosition ? '5px' : '1px'}
                  bgColor={stepIndex < currentPosition ? 'oceanBoatBlue' : 'taupeGray'}
                  top={`calc(50% - ${stepIndex < currentPosition ? '0.30' : '0.15'}rem)`}
                  left="60px"
                  transition="all ease-in 0.2s"
                />
              )}
            </Flex>
            {stepIndex === position && (
              <Text color={stepColor(status)} textTransform="uppercase">
                {label}
              </Text>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default FlowProgressBar;
