import {
  BoxProps,
  Center,
  Flex,
  FlexProps,
  Image,
  Radio,
  RadioGroup,
  SimpleGrid,
  SimpleGridProps,
  Text,
  TextProps,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { useState } from 'react';

import { Checkmark } from '../assets/Images';
import { LDFlags } from '../constants/flags';
import { useFlag } from '../hooks';

interface RadioCardButtonProps extends BoxProps {
  compact?: boolean;
  isChecked?: boolean;
}

const RadioCardButton = ({ children, ...props }: RadioCardButtonProps) => {
  const { state, getInputProps, getRadioProps } = useRadio(props);

  // <Flex> puts an error in the console when it takes in `isChecked` as a prop
  // eslint-disable-next-line no-param-reassign
  delete props.isChecked;
  return (
    <Flex as="label">
      <input {...getInputProps()} />
      <Flex
        {...getRadioProps()}
        h="full"
        borderRadius="lg"
        borderRightRadius="lg"
        w="100%"
        py={7}
        borderRight="1px lightGray solid"
        alignItems="center"
        justifyContent="center"
        px={{ base: 6, lg: 12 }}
        shadow={state.isChecked ? 'none' : 'md'}
        _hover={{ shadow: state.isChecked ? 'none' : 'lg' }}
        _checked={{ bg: 'royalBlueLight', color: 'white' }}
        bg="white"
        position="relative"
        transitionDuration=".2s"
        cursor="pointer"
        {...props}
      >
        {children}
        <Image
          zIndex={4}
          hidden={!state.isChecked}
          h="28px"
          w="28px"
          src={Checkmark}
          alt="Checkmark"
          position="absolute"
          bottom="-15px"
          left={0}
          right={0}
          mx="auto"
          width="40%"
        />
      </Flex>
    </Flex>
  );
};

interface RadioCardProps extends FlexProps {
  label: string;
}

const RadioCard = ({ label, ...props }: RadioCardProps) => {
  const { state, getRadioProps } = useRadio(props);

  return (
    <Flex
      as="label"
      {...getRadioProps()}
      color="charcoal"
      border="1px solid"
      fontWeight="semibold"
      borderColor={state.isChecked ? 'blue.500' : 'grayDarkBackground'}
      borderRadius="3px"
      padding="10px"
      mb="16px"
      transition="all 0.2s"
      cursor="pointer"
    >
      <Radio
        transition="all 0.2s"
        isChecked={state.isChecked}
        {...props}
        _before={{ left: '50%', transform: 'translate(-50%, 0)', margin: '0 auto 0 0' }}
      />
      <Text ml={4}>{label}</Text>
    </Flex>
  );
};

interface ButtonedRadioGroupProps extends Omit<SimpleGridProps, 'defaultValue' | 'onChange'> {
  value?: string | number | boolean;
  options: { label: string; value: string | number | boolean }[];
  name: string;
  defaultValue?: string | number | boolean;
  onChange?: (value: string) => void | Promise<void>;
  reverseOrder?: boolean;
  itemProps?: FlexProps;
  itemTextProps?: TextProps;
  collapseOnMobile?: boolean;
}

const ButtonedRadioGroup = ({
  value,
  options,
  name,
  defaultValue,
  onChange,
  reverseOrder,
  collapseOnMobile = true,
  itemProps,
  itemTextProps,
  ...rest
}: ButtonedRadioGroupProps) => {
  const defaultValueString = defaultValue?.toString();
  const { getRootProps, getRadioProps } = useRadioGroup({
    value: value?.toString(),
    name,
    defaultValue: defaultValueString,
    onChange,
  });

  const reverse = reverseOrder ? '-reverse' : '';
  const showStackedOptions = useFlag(LDFlags.STACKED_RADIO_BUTTONS);

  const [radioGroupValue, setRadioGroupValue] = useState(defaultValueString);

  return (
    <SimpleGrid
      {...getRootProps()}
      direction={{
        base: collapseOnMobile ? `column${reverse}` : `row${reverse}`,
        lg: `row${reverse}`,
      }}
      alignSelf={{ base: 'center', lg: 'start' }}
      spacing={2}
      w="100%"
      {...rest}
      {...(showStackedOptions && { columns: { base: 1 } })}
    >
      {showStackedOptions ? (
        <RadioGroup
          onChange={setRadioGroupValue}
          value={radioGroupValue}
          defaultValue={defaultValueString}
        >
          {options.map(({ label, value: optionValue }) => (
            <RadioCard
              {...itemProps}
              {...getRadioProps({ value: optionValue.toString() })}
              key={label}
              label={label}
            />
          ))}
        </RadioGroup>
      ) : (
        <>
          {options.map(({ label, value: optionValue }) => (
            <RadioCardButton
              {...itemProps}
              {...getRadioProps({ value: optionValue.toString() })}
              key={label}
            >
              <Center>
                <Text textAlign="center" {...itemTextProps}>
                  {label}
                </Text>
              </Center>
            </RadioCardButton>
          ))}
        </>
      )}
    </SimpleGrid>
  );
};

export default ButtonedRadioGroup;
