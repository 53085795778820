import { useQuery } from '@apollo/client';

import { OtherLienholderSlug } from '../../constants/lienholders';
import { TemporaryInfo } from '../../gql/generated/graphql';
import { lienholderQuery } from '../../gql/prs/lienholderGql';
import { InstructionSet, InstructionType } from '../../gql/prs/types';
import OtherPayoff from './components/OtherPayoff';
import PayoffTemplate from './components/PayoffTemplate';
import YouAreAPioneer from './components/YouAreAPioneer';

const LienholderPayoffInstructions = ({ info }: { info: TemporaryInfo }) => {
  const tempInfoData = info?.data;

  const { data: lienholderRequirements } = useQuery(lienholderQuery, {
    skip:
      !tempInfoData?.state ||
      !tempInfoData?.lienholder_slug ||
      tempInfoData?.lienholder_slug === OtherLienholderSlug,
    variables: { state: tempInfoData?.state, slug: tempInfoData?.lienholder_slug },
  });

  const lienholder = lienholderRequirements?.lienholder;
  const requirements = lienholder?.requirements_to_get_payoff;
  const instructionSets = requirements?.instruction_sets;

  if (tempInfoData?.lienholder_slug === OtherLienholderSlug) {
    return <OtherPayoff />;
  }

  if (instructionSets.length === 0) {
    return (
      <YouAreAPioneer
        name={lienholder?.display_name}
        phone={lienholder?.phone_numbers?.[0]?.phone_numbers?.[0]}
        website={lienholder?.website_url}
      />
    );
  }

  const webInstructions = instructionSets.filter(
    (instSet: InstructionSet) => instSet.type === InstructionType.WEBSITE,
  );
  const appInstructions = instructionSets.filter(
    (instSet: InstructionSet) => instSet.type === InstructionType.MOBILE_APP,
  );
  const phoneInstructions = instructionSets.filter(
    (instSet: InstructionSet) =>
      instSet.type !== InstructionType.WEBSITE && instSet.type !== InstructionType.MOBILE_APP,
  );
  const phones = phoneInstructions
    .map((instSet: InstructionSet) => instSet.phone_number)
    .filter((v: string) => v);

  return (
    <PayoffTemplate
      lienholderName={lienholder?.display_name}
      phoneInstructions={phoneInstructions || []}
      appInstructions={appInstructions || []}
      webInstructions={webInstructions || []}
      website={lienholder?.website_url}
      phones={phones || []}
    />
  );
};

export default LienholderPayoffInstructions;
