import { VEHICLE_COLORS } from '../constants/vehicleColors';

export enum Images {
  // Acquisition Vehicle Pictures
  UploadArrow = 'upload_arrow.svg',

  // All Done
  CouchSleep = 'couch_sleep.svg',

  //
  OkGestureGuy = 'ok_gesture_guy.svg',

  // Cobuyer
  TwoUsers = 'TwoUsers.svg',

  // Login
  TextMessage = 'text.svg',

  // Car-Detail, Color, KBB
  SedanBeige = 'vehicles/sedan_beige.svg',
  SedanBlack = 'vehicles/sedan_black.svg',
  SedanBlue = 'vehicles/sedan_blue.svg',
  SedanBrown = 'vehicles/sedan_brown.svg',
  SedanGray = 'vehicles/sedan_gray.svg',
  SedanOrange = 'vehicles/sedan_orange.svg',
  SedanRed = 'vehicles/sedan_red.svg',
  SedanSilver = 'vehicles/sedan_silver.svg',
  SedanWhite = 'vehicles/sedan_white.svg',
  SedanGold = 'vehicles/sedan_gold.svg',
  SedanGreen = 'vehicles/sedan_green.svg',
  SedanPurple = 'vehicles/sedan_purple.svg',
  SedanYellow = 'vehicles/sedan_yellow.svg',
  SuvBeige = 'vehicles/SUV_beige.svg',
  SuvBlack = 'vehicles/SUV_black.svg',
  SuvBlue = 'vehicles/SUV_blue.svg',
  SuvBrown = 'vehicles/SUV_brown.svg',
  SuvGold = 'vehicles/SUV_gold.svg',
  SuvGray = 'vehicles/SUV_gray.svg',
  SuvOrange = 'vehicles/SUV_orange.svg',
  SuvRed = 'vehicles/SUV_red.svg',
  SuvSilver = 'vehicles/SUV_silver.svg',
  SuvWhite = 'vehicles/SUV_white.svg',
  SuvGreen = 'vehicles/SUV_green.svg',
  SuvPurple = 'vehicles/SUV_purple.svg',
  SuvYellow = 'vehicles/SUV_yellow.svg',
  TruckBeige = 'vehicles/truck_beige.svg',
  TruckBlack = 'vehicles/truck_black.svg',
  TruckBlue = 'vehicles/truck_blue.svg',
  TruckBrown = 'vehicles/truck_brown.svg',
  TruckGold = 'vehicles/truck_gold.svg',
  TruckGrey = 'vehicles/truck_grey.svg',
  TruckOrange = 'vehicles/truck_orange.svg',
  TruckRed = 'vehicles/truck_red.svg',
  TruckSilver = 'vehicles/truck_silver.svg',
  TruckWhite = 'vehicles/truck_white.svg',
  TruckGreen = 'vehicles/truck_green.svg',
  TruckPurple = 'vehicles/truck_purple.svg',
  TruckYellow = 'vehicles/truck_yellow.svg',

  // Home
  Arrow = 'arrow.svg',
  PaymentCalculatorIcon = 'payment-calculator-icon.svg',
  GoogleReviews = 'google-reviews.svg',
  EmptyCouch = 'couch_empty_no_back.svg',
  VinFront = 'vin_front.svg',
  VinSide = 'vin_side.svg',
  Wave = 'wave.svg',
  SparkAward = 'SparkAward.png',
  TrustPilotIcon = 'trust-pilot-icon.svg',
  Inc5000 = 'inc_5000.svg',
  BestMoneyLogo = 'best_money_logo.svg',

  // Lienholder Selection
  Checkmark = 'Checkmark.svg',
  DragAndDrop = 'drag_and_drop.svg',

  MagnifyingGlass = 'magnifying_glass.svg',

  // Mileage
  Odometer = 'odometer.svg',

  // Nav
  Logo = 'lease-end-logo.png',
  SmallLogo = 'small-lease-end-logo.png',

  // Payment Estimate
  Lock = 'lock_circle.svg',

  // Finance Logos
  AllyLogo = 'lienholders/Finance_Logos_Ally_Color.png',

  CloudSearch = 'cloud_search.gif',

  // VSC Selection
  AllyColor = 'lienholders/Finance_Logos_Ally_Color.png',

  // Gap Selection
  GapCoverageExample = 'gap_coverage_example.png',
  GapBadge = 'gap_badge.svg',
  VscBadge = 'vsc_badge.svg',

  // Final Summary
  PaymentCalendar = 'payment_calendar.svg',

  // Reviews
  BBB = 'bbb_logo.svg',

  AngryGuy = 'angry_guy.svg',

  // Documents
  JumpingMan = 'jumping-man.svg',
  SignDoc = 'sign-doc.svg',
  Esign = 'esign.svg',

  CuriousPurpleGuy = 'curious_purple_guy.svg',
  MagnifyingGlassPurpleGuy = 'magnifying_glass_purple_guy.svg',
  GreenCheckPurpleGuy = 'green_check_purple_guy.svg',
  OdometerPurpleGuy = 'odometer_purple_guy.svg',
  PointingGuy = 'pointing_guy.svg',
  PointingGuyFlipped = 'pointing_guy_flipped.svg',

  // Loan accordion
  HousePurpleGuy = 'house_purple_guy.svg',
  JobCardPurpleGuy = 'job_card_purple_guy.svg',
  DriverLicensePurpleGuy = 'driver_license_purple_guy.svg',
  PointingUpPurpleGuy = 'pointing_up_purple_guy.svg',

  AccountChair = 'auth/account_chair.svg',

  // 404 Page
  Background404 = '404Page_Background.svg',
  Foreground404 = '404Page_Foreground.svg',

  // Dashboard
  CelebrationImage = 'celebration-image.png',

  // Lease Peace
  AntiBot = 'AntiBot.png',
  Dollaritas = 'Dollaritas.svg',
  GreenHandKeyWithOutline = 'greenHandKeyWithOutline.png',
  LeasePeaceBackground = 'LeasePeaceBackground.svg',
  LeasePeaceMeditator = 'LeasePeaceMeditator.svg',
  LeasePeaceSymbol = 'LeasePeaceSymbol.svg',
  LeasePeaceVan = 'LeasePeaceVan.svg',
  OdometerPointer = 'OdometerPointer.png',
  ResultsCheck = 'ResultsCheck.svg',

  DealProcessing = 'deal_processing.gif',
  DealProcessingComplete = 'deal_processing_complete.png',

  // Getting Started
  NoFeeGuarantee = 'NoFee_Guarantee.svg',

  // bank logos
  AllyBankLogo = 'banks/ally_logo.png',
  CapitalOneBankLogo = 'banks/capital_one_logo.svg',
  FirstInterstateBankLogo = 'banks/first_interstate_bank_logo.png',
  MacuBankLogo = 'banks/macu_logo.png',
  TdBankLogo = 'banks/td_bank_logo.png',

  JulyPromoMobile = 'july-promo-mobile.png',
  JulyPromoSidebar = 'july-promo-sidebar.png',

  Confetti = 'confetti.svg',
  Advisor = 'purple-advisor.svg',

  CreditFrozen = 'credit_frozen.svg',
  EquifaxLogo = 'equifax_logo.svg',
  ExperianLogo = 'experian_logo.svg',
  TransUnionLogo = 'transunion_logo.svg',

  LendingTreeLogo = 'lending_tree_logo.svg',
}

export const getImage = (i: string): string => `/leaseend-com-images/${i}`;

// Exported Images //
export const AllyLogo = getImage(Images.AllyLogo);
export const CloudSearch = getImage(Images.CloudSearch);

export const AngryGuy = getImage(Images.AngryGuy);
export const Arrow = getImage(Images.Arrow);
export const BBBLogo = getImage(Images.BBB);
export const GoogleReviews = getImage(Images.GoogleReviews);
export const BestMoneyLogo = getImage(Images.BestMoneyLogo);
export const Checkmark = getImage(Images.Checkmark);
export const CouchSleep = getImage(Images.CouchSleep);
export const DragAndDrop = getImage(Images.DragAndDrop);
export const EmptyCouch = getImage(Images.EmptyCouch);
export const GapCoverageExample = getImage(Images.GapCoverageExample);
export const GapBadge = getImage(Images.GapBadge);
export const Lock = getImage(Images.Lock);
export const Logo = getImage(Images.Logo);
export const SmallLogo = getImage(Images.SmallLogo);
export const MagnifyingGlass = getImage(Images.MagnifyingGlass);
export const Odometer = getImage(Images.Odometer);
export const OkGestureGuy = getImage(Images.OkGestureGuy);
export const PaymentCalculatorIcon = getImage(Images.PaymentCalculatorIcon);
export const PaymentCalendar = getImage(Images.PaymentCalendar);
export const SedanBeige = getImage(Images.SedanBeige);
export const SedanBlack = getImage(Images.SedanBlack);
export const SedanBlue = getImage(Images.SedanBlue);
export const SedanBrown = getImage(Images.SedanBrown);
export const SedanGray = getImage(Images.SedanGray);
export const SedanOrange = getImage(Images.SedanOrange);
export const SedanRed = getImage(Images.SedanRed);
export const SedanSilver = getImage(Images.SedanSilver);
export const SedanWhite = getImage(Images.SedanWhite);
export const SedanGold = getImage(Images.SedanGold);
export const SedanGreen = getImage(Images.SedanGreen);
export const SedanPurple = getImage(Images.SedanPurple);
export const SedanYellow = getImage(Images.SedanYellow);
export const SparkAward = getImage(Images.SparkAward);
export const SuvBeige = getImage(Images.SuvBeige);
export const SuvBlack = getImage(Images.SuvBlack);
export const SuvBlue = getImage(Images.SuvBlue);
export const SuvBrown = getImage(Images.SuvBrown);
export const SuvGold = getImage(Images.SuvGold);
export const SuvGray = getImage(Images.SuvGray);
export const SuvOrange = getImage(Images.SuvOrange);
export const SuvRed = getImage(Images.SuvRed);
export const SuvSilver = getImage(Images.SuvSilver);
export const SuvPurple = getImage(Images.SuvPurple);
export const SuvYellow = getImage(Images.SuvYellow);
export const SuvWhite = getImage(Images.SuvWhite);
export const SuvGreen = getImage(Images.SuvGreen);
export const TextMessage = getImage(Images.TextMessage);
export const TruckBeige = getImage(Images.TruckBeige);
export const TruckBlack = getImage(Images.TruckBlack);
export const TruckBlue = getImage(Images.TruckBlue);
export const TruckBrown = getImage(Images.TruckBrown);
export const TruckGold = getImage(Images.TruckGold);
export const TruckGrey = getImage(Images.TruckGrey);
export const TruckOrange = getImage(Images.TruckOrange);
export const TruckRed = getImage(Images.TruckRed);
export const TruckSilver = getImage(Images.TruckSilver);
export const TruckPurple = getImage(Images.TruckPurple);
export const TruckYellow = getImage(Images.TruckYellow);
export const TruckWhite = getImage(Images.TruckWhite);
export const TruckGreen = getImage(Images.TruckGreen);
export const TrustPilotIcon = getImage(Images.TrustPilotIcon);
export const TwoUsers = getImage(Images.TwoUsers);
export const UploadArrow = getImage(Images.UploadArrow);
export const VinSide = getImage(Images.VinSide);
export const VinFront = getImage(Images.VinFront);
export const VscBadge = getImage(Images.VscBadge);
export const Wave = getImage(Images.Wave);
export const Inc5000 = getImage(Images.Inc5000);
export const JumpingMan = getImage(Images.JumpingMan);
export const SignDoc = getImage(Images.SignDoc);
export const Esign = getImage(Images.Esign);
export const CuriousPurpleGuy = getImage(Images.CuriousPurpleGuy);
export const MagnifyingGlassPurpleGuy = getImage(Images.MagnifyingGlassPurpleGuy);
export const GreenCheckPurpleGuy = getImage(Images.GreenCheckPurpleGuy);
export const OdometerPurpleGuy = getImage(Images.OdometerPurpleGuy);
export const PointingGuy = getImage(Images.PointingGuy);
export const PointingGuyFlipped = getImage(Images.PointingGuyFlipped);
export const AccountChair = getImage(Images.AccountChair);
export const Background404 = getImage(Images.Background404);
export const Foreground404 = getImage(Images.Foreground404);
export const CelebrationImage = getImage(Images.CelebrationImage);
export const HousePurpleGuy = getImage(Images.HousePurpleGuy);
export const JobCardPurpleGuy = getImage(Images.JobCardPurpleGuy);
export const DriverLicensePurpleGuy = getImage(Images.DriverLicensePurpleGuy);
export const PointingUpPurpleGuy = getImage(Images.PointingUpPurpleGuy);
export const AntiBot = getImage(Images.AntiBot);
export const Dollaritas = getImage(Images.Dollaritas);
export const GreenHandKeyWithOutline = getImage(Images.GreenHandKeyWithOutline);
export const LeasePeaceBackground = getImage(Images.LeasePeaceBackground);
export const LeasePeaceMeditator = getImage(Images.LeasePeaceMeditator);
export const LeasePeaceSymbol = getImage(Images.LeasePeaceSymbol);
export const LeasePeaceVan = getImage(Images.LeasePeaceVan);
export const OdometerPointer = getImage(Images.OdometerPointer);
export const ResultsCheck = getImage(Images.ResultsCheck);
export const NoFeeGuarantee = getImage(Images.NoFeeGuarantee);

export const DealProcessing = getImage(Images.DealProcessing);
export const DealProcessingComplete = getImage(Images.DealProcessingComplete);

export const AllyBankLogo = getImage(Images.AllyBankLogo);
export const CapitalOneBankLogo = getImage(Images.CapitalOneBankLogo);
export const FirstInterstateBankLogo = getImage(Images.FirstInterstateBankLogo);
export const MacuBankLogo = getImage(Images.MacuBankLogo);
export const TdBankLogo = getImage(Images.TdBankLogo);

export const JulyPromoMobile = getImage(Images.JulyPromoMobile);
export const JulyPromoSidebar = getImage(Images.JulyPromoSidebar);

export const Confetti = getImage(Images.Confetti);
export const Advisor = getImage(Images.Advisor);

export const CreditFrozen = getImage(Images.CreditFrozen);
export const EquifaxLogo = getImage(Images.EquifaxLogo);
export const ExperianLogo = getImage(Images.ExperianLogo);
export const TransUnionLogo = getImage(Images.TransUnionLogo);

export const LendingTreeLogo = getImage(Images.LendingTreeLogo);

type VehicleImage = { value: VEHICLE_COLORS; key: string };

export const Cars: VehicleImage[] = [
  { value: VEHICLE_COLORS.Beige, key: SedanBeige },
  { value: VEHICLE_COLORS.Black, key: SedanBlack },
  { value: VEHICLE_COLORS.Blue, key: SedanBlue },
  { value: VEHICLE_COLORS.Brown, key: SedanBrown },
  { value: VEHICLE_COLORS.Gray, key: SedanGray },
  { value: VEHICLE_COLORS.Green, key: SedanGreen },
  { value: VEHICLE_COLORS.Orange, key: SedanOrange },
  { value: VEHICLE_COLORS.Red, key: SedanRed },
  { value: VEHICLE_COLORS.Silver, key: SedanSilver },
  { value: VEHICLE_COLORS.White, key: SedanWhite },
  { value: VEHICLE_COLORS.Purple, key: SedanPurple },
  { value: VEHICLE_COLORS.Yellow, key: SedanYellow },
];
export const Suvs: VehicleImage[] = [
  { value: VEHICLE_COLORS.Beige, key: SuvBeige },
  { value: VEHICLE_COLORS.Black, key: SuvBlack },
  { value: VEHICLE_COLORS.Blue, key: SuvBlue },
  { value: VEHICLE_COLORS.Brown, key: SuvBrown },
  { value: VEHICLE_COLORS.Gray, key: SuvGray },
  { value: VEHICLE_COLORS.Green, key: SuvGreen },
  { value: VEHICLE_COLORS.Orange, key: SuvOrange },
  { value: VEHICLE_COLORS.Red, key: SuvRed },
  { value: VEHICLE_COLORS.Silver, key: SuvSilver },
  { value: VEHICLE_COLORS.White, key: SuvWhite },
  { value: VEHICLE_COLORS.Purple, key: SuvPurple },
  { value: VEHICLE_COLORS.Yellow, key: SuvYellow },
];
export const Trucks: VehicleImage[] = [
  { value: VEHICLE_COLORS.Beige, key: TruckBeige },
  { value: VEHICLE_COLORS.Black, key: TruckBlack },
  { value: VEHICLE_COLORS.Blue, key: TruckBlue },
  { value: VEHICLE_COLORS.Brown, key: TruckBrown },
  { value: VEHICLE_COLORS.Gray, key: TruckGrey },
  { value: VEHICLE_COLORS.Green, key: TruckGreen },
  { value: VEHICLE_COLORS.Orange, key: TruckOrange },
  { value: VEHICLE_COLORS.Red, key: TruckRed },
  { value: VEHICLE_COLORS.Silver, key: TruckSilver },
  { value: VEHICLE_COLORS.White, key: TruckWhite },
  { value: VEHICLE_COLORS.Purple, key: TruckPurple },
  { value: VEHICLE_COLORS.Yellow, key: TruckYellow },
];
