import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';

import { LEAccordionItem } from '../../../components/LEAccordion';
import TextWithTooltip from '../../../components/TextWithTooltip';
import FileManager from '../../../components/UploadDocuments/FileManager';
import {
  DealMediaTypeEnum,
  useCreateExternalTempInfoUploadUrlMutation,
  useTemporaryInfoUpdateMutation,
} from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import { useStep } from '../../../hooks/useStep';
import { logger } from '../../../services/sentry';
import { FlowFile, convertFile, uploadFileToS3 } from '../../../utils/media';

const WHAT_ARE_PAYOFF_DOCS =
  'A lease payoff quote is a document from your lienholder that details the total amount needed ' +
  "to buy out your lease, including the car's residual value, remaining payments, and any " +
  'associated fees or penalties. If you don’t have a copy, you can request one from your ' +
  'lienholder, or they may provide it online. You can also skip this optional step!';

const PayoffDocumentsUpload = () => {
  const { info } = useTempInfo();
  const [loading, setLoading] = useState(false);
  const filesState = useState<FlowFile[]>(info?.data?.uploaded_files ?? []);
  const [files] = filesState;
  const step = useStep();

  const [createUploadUrlCom] = useCreateExternalTempInfoUploadUrlMutation();
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const saveToTempInfo = async (attachedFiles: File[], isSaveOnUpload = false) => {
    try {
      const newFiles = attachedFiles.map((f) => ({
        name: f.name,
        type: f.type,
        size: f.size,
        mediaType: DealMediaTypeEnum.PayoffDocs,
        ...(isSaveOnUpload && { isUploadedToS3: true }),
      }));

      await updateTemporaryInfo({
        variables: {
          info: {
            id: info?.id,
            data: {
              uploaded_files: newFiles,
            },
          },
        },
      });
    } catch (e) {
      logger.error('FileManager.tsx:saveToTempInfo', 'Failed to update temp info', files, e);
    }
  };

  const uploadFiles = async () => {
    setLoading(true);
    try {
      await saveToTempInfo(files, true);

      const uploadPromises = files.map(async (file) => {
        if (!file?.isUploadedToS3) {
          const convertedFile = await convertFile(file, true);

          const { data } = await createUploadUrlCom({
            variables: {
              guid: info?.id ?? '',
              fileName: convertedFile.name,
              fileDescription: DealMediaTypeEnum.PayoffDocs,
            },
          });

          if (data?.createExternalTempInfoUploadUrl?.url) {
            await uploadFileToS3({
              file: convertedFile,
              url: data?.createExternalTempInfoUploadUrl?.url,
            });
          }
        }
        await Promise.all(uploadPromises);
      });
    } catch (e) {
      logger.error(
        'PayoffDocumentsUpload.tsx:saveToTempInfo',
        'Failed to update temp info',
        files,
        e,
      );
    }
    setLoading(false);
  };

  return (
    <LEAccordionItem
      accordionKey="uploadLeaseAgreement"
      title={
        <>
          UPLOAD PAYOFF DOCUMENTS{' '}
          <Text as="span" fontSize={14}>
            (OPTIONAL)
          </Text>
        </>
      }
    >
      <VStack>
        <Box bgColor="bananaMania" borderRadius="5px" px="10px" py="15px">
          <Text textAlign="center">
            <b>
              This step is <em>optional</em>, but it can speed up the buyout process and ensure that
              all the information is accurate.
            </b>
          </Text>
        </Box>
        <TextWithTooltip label={WHAT_ARE_PAYOFF_DOCS}>
          What are "Payoff Documents" and where do I find them?
        </TextWithTooltip>
        <FileManager
          copy={{
            base: 'Tap to upload your payoff documents',
            md: (
              <>
                Drag & drop your payoff documents here or <u>click to upload</u>
              </>
            ),
          }}
          tempInfo={info}
          filesState={filesState}
          updateTempInfo={saveToTempInfo}
        />
        <Button
          isLoading={loading}
          onClick={async () => {
            await uploadFiles();
            step.moveNext();
          }}
        >
          NEXT
        </Button>
      </VStack>
    </LEAccordionItem>
  );
};

export default PayoffDocumentsUpload;
