import {
  Box,
  Button,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { Input, Select } from '../../components/formComponents';
import {
  LICENSE_PLATE_NAME,
  VIN_NAME,
  licensePlateValidationSchema,
  vinValidationSchema,
} from '../../components/licensePlateOrVinInput/LicensePlateOrVinInput';
import ContinueContainer from '../../components/ui/GetStarted/ContinueContainer';
import { stateAbbreviations, statesArray } from '../../constants/states';
import { ERROR_TYPES } from '../../constants/tempInfos';
import { GETTING_STARTED } from '../../constants/urls';
import {
  AffiliateDetailsQuery,
  TemporaryData,
  useAffiliateDetailsQuery,
  useDecodeAffiliateCarDetailsLazyQuery,
  useTemporaryInfoUpdateMutation,
} from '../../gql/generated/graphql';
import { useStep } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { affiliateProviderLogo } from '../../utils/affiliateProviders';
import { formatMoney } from '../../utils/helpers';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { capitalizeFirstLetter, formatPhoneNumber } from '../../utils/text/text';
import { INVALID_LICENSE_PLATE_MSG } from '../../utils/validation/licenseNumberValidator';

export const DECODING_SERVICE_ISSUE_MSG_SHORT =
  'Our decoding service is experiencing issues. Please try again later.';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;

interface AffiliatePresentationProps extends FlexProps {
  affiliateDetails?: AffiliateDetailsQuery['affiliateDetails'];
  affiliateTempInfoData?: TemporaryData;
}

const AffiliatePresentation = ({
  affiliateDetails,
  affiliateTempInfoData,
  ...rest
}: AffiliatePresentationProps) => (
  <Flex
    flexDir="column"
    alignItems={{ base: 'center', md: 'flex-start' }}
    textAlign={{ base: 'center', md: 'left' }}
    {...rest}
  >
    <Image
      mb={6}
      w="150px"
      alt="logo"
      src={affiliateProviderLogo(affiliateDetails?.affiliate_provider?.slug)}
    />
    <Divider
      borderColor="grayDarkBackground"
      mb={5}
      mt={2}
      display={{ base: 'block', md: 'none' }}
    />
    <Heading as="h1" mb={4} fontSize="44px">
      Hi, {capitalizeFirstLetter(affiliateTempInfoData?.first_name)}!
    </Heading>
    <Text mb={6} fontSize="16px" maxW={{ base: '300px', md: 'unset' }}>
      We've worked with {affiliateDetails?.affiliate_provider?.name} to get you pre-approved for an
      auto loan!
    </Text>
  </Flex>
);

interface Props {
  affiliateTempInfoData?: TemporaryData;
}

const AffiliateWidget = ({ affiliateTempInfoData }: Props) => {
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const { pathname } = useLocation();
  const history = useHistory();
  const step = useStep();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [decodeCar] = useDecodeAffiliateCarDetailsLazyQuery();
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const { data: affiliateDetailsData } = useAffiliateDetailsQuery({
    variables: {
      affiliateExternalId: affiliateTempInfoData?.affiliate_external_id,
    },
  });
  const affiliateDetails = affiliateDetailsData?.affiliateDetails;
  const offer = affiliateDetails?.decision_response?.offers?.[0];
  const hasVin = !!affiliateTempInfoData?.vin;

  const enterFlow = () => {
    history.push(affiliateTempInfoData?.last_url || GETTING_STARTED);
  };

  const handleSubmit = async (values: FormFields, { setFieldError }: FormikHelpers<FormFields>) => {
    const vin = values.vin?.toUpperCase();
    const licensePlate = values.licensePlate?.replace(/\s/g, '')?.toUpperCase();
    const state = values.state?.toUpperCase();

    const isVin = !!vin;

    if (!isVin && (!licensePlate || !stateAbbreviations.includes(state))) {
      return;
    }

    const { data: decodedCar, error } = await decodeCar({
      variables: {
        vin,
        license_plate_number: licensePlate,
        license_plate_state: state,
      },
    });

    if (error) {
      // should we print rudderstack here?
      rudderanalytics.track(RudderEvent.Error, {
        error_message: error.message,
        vin,
        license_plate: licensePlate,
        license_plate_state: state,
        pathname,
        date: new Date().toISOString(),
      });
      if (error.message === ERROR_TYPES.DECODING_SERVICE_ISSUE) {
        setFieldError('vin', DECODING_SERVICE_ISSUE_MSG_SHORT);
      }
      if (error.message === ERROR_TYPES.INVALID_LICENSE_PLATE) {
        setFieldError('licensePlate', INVALID_LICENSE_PLATE_MSG);
      }
      return;
    }

    const carDetails = decodedCar?.decodeAffiliateCarDetails;

    const { data: tempInfo } = await updateTemporaryInfo({
      variables: {
        info: {
          id: guid,
          data: {
            license_plate_number: carDetails?.license_plate_number,
            license_plate_state: carDetails?.license_plate_state,
            vin: carDetails?.vin,
            year: carDetails?.year,
            make: carDetails?.make,
            model: carDetails?.model,
            fuel_type: carDetails?.fuel_type,
            vehicle_type: carDetails?.vehicle_type,
            kbb_trim_name: carDetails?.kbb_trim_name,
          },
        },
      },
    });

    const tempInfoData = tempInfo?.temporaryInfoUpdate?.data;

    rudderanalytics.track(RudderEvent.VinProvided, {
      year: tempInfoData?.year || undefined,
      make: tempInfoData?.make || undefined,
      model: tempInfoData?.model || undefined,
      vin: tempInfoData?.vin || undefined,
      zip: tempInfoData?.zip || undefined,
      license_plate: tempInfoData?.license_plate_number || undefined,
      license_plate_state: tempInfoData?.license_plate_state || undefined,
      fuel_type: tempInfoData?.fuel_type || undefined,
      vehicle_type: tempInfoData?.vehicle_type || undefined,
    });

    step.moveNext({}, true);
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        licensePlate: '',
        state: '',
        vin: '',
      }}
      validationSchema={isLicensePlate ? licensePlateValidationSchema : vinValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setValues, setErrors }) => (
        <Flex flexDir={{ base: 'column', md: 'row' }}>
          <AffiliatePresentation
            affiliateDetails={affiliateDetails}
            affiliateTempInfoData={affiliateTempInfoData}
            display={{ base: 'flex', md: 'none' }}
          />
          <Text
            mb={2}
            textAlign="center"
            maxW="370px"
            fontWeight="bold"
            color="royalBlue"
            display={{ base: 'block', md: 'none' }}
            fontSize="12px"
          >
            NEXT, TELL US ABOUT YOUR CAR TO FINISH THE PROCESS
          </Text>
          <Box
            maxW={{ base: '370px', md: '500px' }}
            rounded="10px"
            p={6}
            {...((!hasVin || !isMobile) && {
              bg: 'white',
              border: '1px solid',
              borderColor: 'gray.200',
              shadow: '2xl',
            })}
          >
            <AffiliatePresentation
              affiliateDetails={affiliateDetails}
              affiliateTempInfoData={affiliateTempInfoData}
              display={{ base: 'none', md: 'flex' }}
            />
            {hasVin ? (
              <Flex flexDir="column" alignItems="center" pt="25px">
                <ContinueContainer
                  enterFlow={enterFlow}
                  canStartOver={false}
                  startOver={() => null}
                />
              </Flex>
            ) : (
              <Form style={{ width: '100%' }}>
                <Flex flexDirection="column">
                  <Text fontWeight="bold" color="royalBlue" display={{ base: 'none', md: 'block' }}>
                    NEXT, TELL US ABOUT YOUR CAR TO FINISH THE PROCESS
                  </Text>
                  <Flex mx={0} mt={3} minH="50px">
                    {isLicensePlate ? (
                      <>
                        <Flex w="65%">
                          <Input
                            name="licensePlate"
                            _input={{
                              h: 14,
                              borderWidth: 1,
                              borderColor: 'cornflower',
                              borderLeftRadius: 5,
                              borderRightRadius: 0,
                              fontSize: 20,
                              fontWeight: 500,
                              letterSpacing: 0,
                            }}
                            placeholder="License Plate"
                          />
                        </Flex>
                        <Flex flex={1}>
                          <Select
                            _container={{
                              bg: 'white',
                              borderWidth: 1,
                              borderLeftWidth: 0,
                              borderColor: 'cornflower',
                              borderRightRadius: 5,
                              height: 14,
                            }}
                            _control={{
                              borderRightRadius: 5,
                              borderLeftRadius: 0,
                              h: '47px',
                              border: 0,
                              fontSize: 20,
                              fontWeight: 500,
                            }}
                            name="state"
                            placeholder="State"
                            options={statesArray}
                          />
                        </Flex>
                      </>
                    ) : (
                      <Input
                        name="vin"
                        placeholder="Your vehicle's 17-digit VIN"
                        _input={{
                          h: 14,
                          borderWidth: 1,
                          borderColor: 'cornflower',
                          borderRadius: 5,
                          fontSize: 20,
                          fontWeight: 500,
                          letterSpacing: 0,
                        }}
                      />
                    )}
                  </Flex>
                  <Text
                    mt={5}
                    onClick={() => {
                      setCurrentInput(isLicensePlate ? VIN_NAME : LICENSE_PLATE_NAME);
                      setValues({ vin: '', licensePlate: '', state: '' });
                      setErrors({});
                    }}
                    cursor="pointer"
                    fontSize={14}
                    fontWeight={600}
                    color="oceanBoatBlue"
                    textAlign="left"
                  >
                    {isLicensePlate ? 'Use VIN' : 'Use License Plate'}
                  </Text>
                  <Flex justifyContent="flex-end">
                    <Button type="submit" isLoading={isSubmitting}>
                      CONTINUE
                    </Button>
                  </Flex>
                </Flex>
              </Form>
            )}
          </Box>
          <Divider
            borderColor="grayDarkBackground"
            mb={4}
            mt={10}
            display={{ base: 'block', md: 'none' }}
          />
          <Flex maxW={{ base: '370px', md: '500px' }} mt={8} flexDir="column" color="white">
            <Box
              shadow="2xl"
              w={{ base: '100%', md: '260px', '2xl': '280px' }}
              bgColor="royalBlue"
              p={6}
              borderRadius={{ base: '10px 10px 0 0', md: '0 10px 0 0' }}
            >
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                fontWeight="extrabold"
                fontSize="18px"
                textTransform="uppercase"
                mb={3}
              >
                {capitalizeFirstLetter(affiliateTempInfoData?.first_name)}{' '}
                {capitalizeFirstLetter(affiliateTempInfoData?.last_name)}
              </Text>
              <Text textTransform="capitalize">{affiliateTempInfoData?.address_line}</Text>
              <Text mb={2} textTransform="capitalize">
                {capitalizeFirstLetter(affiliateTempInfoData?.city)}, {affiliateTempInfoData?.state}{' '}
                {affiliateTempInfoData?.zip}
              </Text>
              <Text mb={2}>{formatPhoneNumber(affiliateTempInfoData?.phone_number)}</Text>
              <Text>{affiliateTempInfoData?.email}</Text>
            </Box>
            <Box
              shadow="2xl"
              w={{ base: '100%', md: '280px', '2xl': '300px' }}
              bgColor="leaseEndBlue"
              p={6}
              borderRadius={{ base: '0 0 10px 10px', md: '0 10px 10px 0' }}
            >
              <Text
                textAlign={{ base: 'center', md: 'left' }}
                fontWeight="extrabold"
                fontSize="18px"
                textTransform="uppercase"
                mb={3}
              >
                LOAN DETAILS
              </Text>
              <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
                <Text fontSize={{ base: '16px', md: '18px' }}>{formatMoney(offer?.amount)}</Text>
                <Text
                  fontSize={{ base: '12px', md: '13px' }}
                  textAlign="right"
                  color="grayDarkBackground"
                >
                  AMOUNT
                </Text>
              </Flex>
              <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
                <Text fontSize={{ base: '16px', md: '18px' }}>{offer?.term} months</Text>
                <Text
                  fontSize={{ base: '12px', md: '13px' }}
                  textAlign="right"
                  color="grayDarkBackground"
                >
                  TERM
                </Text>
              </Flex>
              <Flex mb={2} justifyContent="space-between" alignItems="flex-end">
                <Text fontSize={{ base: '16px', md: '18px' }}>{offer?.apr}%</Text>
                <Text
                  fontSize={{ base: '12px', md: '13px' }}
                  textAlign="right"
                  color="grayDarkBackground"
                >
                  APR
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" fontWeight="bold">
                <Text fontSize={{ base: '16px', md: '18px' }}>
                  {formatMoney(offer?.monthlyPayment)}/mo
                </Text>
                <Text fontSize={{ base: '12px', md: '13px' }} textAlign="right">
                  EST PAYMENT
                </Text>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
    </Formik>
  );
};

export default AffiliateWidget;
