// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';

import EndingInZeroMessage from './EndingInZeroMessage';
import OverMileageFeeWarning from './OverMileageFeeWarning';

interface Props {
  mileage: number;
  mileageWarnings: {
    endingInZero: boolean;
    overMileage: boolean;
  };
}

const MileageWarning = ({ mileage, mileageWarnings }: Props) => {
  const { endingInZero, overMileage } = mileageWarnings;
  const ldClient = useLDClient();
  if (!overMileage && !endingInZero) {
    return null;
  }

  if (overMileage) {
    ldClient?.track('over-mileage-warning', {});
    return <OverMileageFeeWarning mileage={Number(mileage)} />;
  }

  return <EndingInZeroMessage />;
};

export default MileageWarning;
