/* eslint-disable react/no-array-index-key */
import { LDFlags } from '../../../constants/flags';
import { InstructionSet } from '../../../gql/prs/types';
import { useFlag } from '../../../hooks';
import { PAYOFF_PAGE_VARIATIONS } from '../../../pages/PayoffInstructions/constants';
import Label from '../../formComponents/Label';
import Instructions from './Instructions';

const AppInstructions = ({
  lienholderName,
  appInstructions,
}: {
  lienholderName: string;
  appInstructions: InstructionSet[];
}) => {
  const payoffPageVariation = useFlag(LDFlags.PAYOFF_PAGE_VARIATION);
  const isVariation0 = payoffPageVariation === PAYOFF_PAGE_VARIATIONS.variation0;

  return (
    <>
      <Label fontSize={{ md: '16px' }} textTransform={isVariation0 ? 'uppercase' : 'none'}>
        Get your payoff amount and good-through date by opening the {lienholderName} mobile app.
      </Label>
      {appInstructions.map((appInstruction, i) => (
        <Instructions key={`app-instruction-${i}`} instructions={appInstruction.instructions} />
      ))}
    </>
  );
};

export default AppInstructions;
