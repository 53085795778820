import createDescendantContext from '@chakra-ui/descendant';
import { Box, Center, Progress, Text } from '@chakra-ui/react';
import React from 'react';

import { LDFlags } from '../../constants/flags';
import { useFlag } from '../../hooks';
import { LEAccordionProvider, useLEAccordion } from './useLEAccordion';
import { ACCORDION_NAME } from './utils';

const [DescendantsProvider, useDescendantsContext, useDescendants, useDescendant] =
  createDescendantContext();

export { useDescendant, useDescendantsContext };

export interface LEAccordionProps {
  minutes?: number;
  useTracking?: boolean;
  showProgress?: boolean;
  children: React.ReactNode;
  rightSidebar?: React.ReactNode;
  name: ACCORDION_NAME;
}

/**
 * The wrapper that provides context and focus management
 * for all LE accordion items.
 */
const LEAccordion = ({
  minutes,
  useTracking = true,
  showProgress = true,
  children,
  rightSidebar,
  name,
}: LEAccordionProps) => {
  const descendants = useDescendants();
  const context = useLEAccordion({ useTracking, rightSidebar, name });
  const showPageProgressMeter = useFlag(LDFlags.SHOW_PAGE_PROGRESS_METER);

  return (
    <Center width="full">
      <Box width="600px" maxWidth="100%">
        <DescendantsProvider value={descendants}>
          <LEAccordionProvider value={context}>
            {showPageProgressMeter && minutes && (
              <Text color="taupeGray" textAlign="center" mb="10px">
                This section should take about {minutes} {minutes <= 1 ? 'minute' : 'minutes'}
              </Text>
            )}
            {showPageProgressMeter && showProgress && (
              <Progress
                w="95%"
                mx="auto"
                bg="gray.200"
                colorScheme="green"
                rounded="2xl"
                size="sm"
                value={context.accordionProgress}
              />
            )}
            {children}
          </LEAccordionProvider>
        </DescendantsProvider>
      </Box>
    </Center>
  );
};

export default LEAccordion;
