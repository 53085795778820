/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { BiChevronDown } from 'react-icons/bi';

import { Label } from '../../../../../components/formComponents';
import FormErrorMessage from '../../../../../components/formComponents/FormErrorMessage';
import { VEHICLE_COLORS } from '../../../../../constants/vehicleColors';

export const COLORS: { [color in VEHICLE_COLORS]: string } = {
  [VEHICLE_COLORS.Black]: '#000000',
  [VEHICLE_COLORS.Purple]: '#720CB5',
  [VEHICLE_COLORS.Blue]: '#005292',
  [VEHICLE_COLORS.Red]: '#CC1D3A',
  [VEHICLE_COLORS.Brown]: '#885F37',
  [VEHICLE_COLORS.Silver]: '#CCCCCC',
  [VEHICLE_COLORS.Green]: '#017C39',
  [VEHICLE_COLORS.Beige]: '#D9D4AA',
  [VEHICLE_COLORS.Gray]: '#808080',
  [VEHICLE_COLORS.White]: '#FFFFFF',
  [VEHICLE_COLORS.Orange]: '#F7931E',
  [VEHICLE_COLORS.Yellow]: '#FFF200',
};

const blackLabels = ['Silver', 'Beige', 'White', 'Yellow'];

interface Props {
  name?: string;
}

const ColorPicker = ({ name = 'color' }: Props) => {
  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const isInvalid = touched && !!error;

  const getColor = () => {
    if (value === '') {
      if (isInvalid) {
        return 'invalidInputTextRed';
      }
      return 'placeholderGray';
    }
    return blackLabels.includes(value) ? 'black' : 'white';
  };
  return (
    <Flex flexDir="column" alignItems="flex-start" w="100%">
      <Label letterSpacing="1px">Color</Label>
      <Box w="100%">
        <Popover variant="picker">
          {({ onClose }: { onClose: () => void }) => {
            return (
              <>
                <PopoverTrigger>
                  <Button
                    w="100%"
                    background={isInvalid ? 'leaseEndRedBG' : 'white'}
                    color={getColor()}
                    h="35px"
                    p="2px"
                    borderRadius="3px"
                    border="1px solid"
                    borderColor={isInvalid ? 'invalidInputBorderRed' : 'grayDarkBackground'}
                    _hover={{ bgColor: isInvalid ? 'leaseEndRedBG' : 'white' }}
                    textAlign="left"
                  >
                    <Flex
                      flexDir="row"
                      h="100%"
                      w="100%"
                      p="3px 0px 3px 10px"
                      borderRadius="3px"
                      justifyContent="space-between"
                      alignItems="center"
                      bgColor={COLORS[value as VEHICLE_COLORS]}
                      fontSize="16px"
                      fontWeight="semibold"
                    >
                      <Box>{value === '' ? 'Color' : value}</Box>
                      {value === '' && (
                        <Box
                          borderLeft="1px solid"
                          borderColor="grayDarkBackground"
                          p="0 4px"
                          color="rgb(108, 107, 109)"
                        >
                          <Icon as={BiChevronDown} boxSize="20px" />
                        </Box>
                      )}
                    </Flex>
                  </Button>
                </PopoverTrigger>
                <FormControl isInvalid={isInvalid}>
                  <FormErrorMessage mt="5px" color="leaseEndRed">
                    {error}
                  </FormErrorMessage>
                </FormControl>
                <PopoverContent w="250px">
                  <PopoverBody>
                    <SimpleGrid columns={2} spacing={2}>
                      {Object.entries(COLORS).map(([key, c]) => (
                        <Button
                          key={c}
                          background={c}
                          border={key === VEHICLE_COLORS.White ? '1px solid' : 'unset'}
                          borderColor="grayDarkBackground"
                          color={blackLabels.includes(key) ? 'black' : 'white'}
                          h="35px"
                          w="110px"
                          p={0}
                          minWidth="unset"
                          _hover={{ background: c }}
                          onClick={() => {
                            setValue(key as VEHICLE_COLORS);
                            onClose();
                          }}
                        >
                          {key}
                        </Button>
                      ))}
                    </SimpleGrid>
                  </PopoverBody>
                </PopoverContent>
              </>
            );
          }}
        </Popover>
      </Box>
    </Flex>
  );
};

export default ColorPicker;
