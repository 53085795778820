import {
  Box,
  Input as ChakraInput,
  FormControl,
  FormControlProps,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabelProps,
  InputGroup,
  InputProps,
  InputRightElement,
  PropsOf,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import InputMask from 'react-input-mask';

import FormErrorMessage from './FormErrorMessage';
import Label from './Label';

interface Props extends Omit<FormControlProps, 'label'> {
  name: NonNullable<InputProps['name']>;
  placeholder?: NonNullable<InputProps['placeholder']>;
  autoFocus?: boolean;
  debounce?: boolean;
  fontSize?: InputProps['fontSize'];
  helperText?: ReactNode;
  icon?: ReactNode;
  invalid?: boolean;
  label?: ReactNode;
  labelProps?: FormLabelProps;
  mask?: string;
  maxLength?: NonNullable<InputProps['maxLength']>;
  readOnly?: boolean;
  size?: InputProps['size'];
  tabIndex?: number;
  type?: NonNullable<InputProps['type']>;
  value?: string;
  _input?: PropsOf<typeof ChakraInput>;
  _container?: StackProps;
  _errorMessage?: FormErrorMessageProps;
  _helperText?: FormHelperTextProps;
  autoCompleteDisabled?: boolean;
}

const Input = ({
  name,
  placeholder,
  autoFocus = false,
  debounce,
  fontSize,
  helperText,
  icon,
  invalid = false,
  label,
  labelProps,
  mask = '',
  maxLength,
  readOnly,
  size,
  tabIndex,
  type = 'text',
  textTransform = 'none',
  value,
  _input,
  _container,
  _errorMessage,
  _helperText,
  autoCompleteDisabled,
  ...rest
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const [t, setT] = useState<number | null>(null);
  const [tempValue, setTempValue] = useState<string>('');
  const [fpColor, setFPColor] = useState<string>('placeholderGray');

  const { checked, onBlur, multiple } = field;
  const { setValue } = helpers;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value: text } = e.target;
    if (maxLength && text.length > maxLength) {
      text = text.slice(0, maxLength);
    }
    if (debounce) {
      if (t) clearTimeout(t);
      setTempValue(text);
      setT(window.setTimeout(() => setValue(text), 500));
    } else {
      setValue(text);
    }
  };

  const isInvalid = (meta.touched && meta.error != null) || invalid;

  const inputRef = useRef<HTMLInputElement>(null);

  const showFakePlaceholder = !field.value && autoCompleteDisabled;

  return (
    <VStack
      w="100%"
      spacing={0}
      h="100%"
      pointerEvents={readOnly ? 'none' : 'auto'}
      {..._container}
    >
      <FormControl isInvalid={isInvalid} {...rest} position="relative">
        {label && <Label {...labelProps}>{label}</Label>}
        <InputGroup>
          <ChakraInput
            ref={inputRef}
            as={InputMask}
            mask={field.value ? mask : undefined}
            type={type}
            placeholder={autoCompleteDisabled ? 'search term' : placeholder}
            isInvalid={isInvalid}
            size={size}
            fontSize={fontSize}
            maxLength={maxLength}
            checked={checked}
            name={name}
            onBlur={(values: ChangeEvent<HTMLInputElement>) => {
              if (autoCompleteDisabled) setFPColor('placeholderGray');
              onBlur(values);
              handleChange(values);
            }}
            onChange={handleChange}
            value={debounce ? tempValue : value || field.value}
            multiple={multiple}
            autoFocus={autoFocus}
            onFocus={() => (autoCompleteDisabled ? setFPColor('placeholderBlue') : undefined)}
            textTransform={textTransform}
            tabIndex={tabIndex}
            readOnly={readOnly}
            pr={icon ? 8 : 4}
            {..._input}
            _placeholder={{ color: autoCompleteDisabled ? 'transparent' : 'unset' }}
            bgColor={autoCompleteDisabled ? 'transparent' : 'unset'}
          />
          {showFakePlaceholder && (
            <Box
              position="absolute"
              color={fpColor}
              left="12px"
              top="6px"
              onClick={() => inputRef?.current?.focus()}
              fontSize={fontSize}
              fontWeight="semibold"
            >
              {placeholder}
            </Box>
          )}
          {icon && <InputRightElement h="100%">{icon}</InputRightElement>}
        </InputGroup>
        {isInvalid ? (
          <FormErrorMessage mt="5px" ml="2px" color="leaseEndRed" {..._errorMessage}>
            {meta.error}
          </FormErrorMessage>
        ) : helperText ? (
          <FormHelperText mt="5px" ml="2px" color="taupeGray" textAlign="left" {..._helperText}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </VStack>
  );
};

export default Input;
