/* eslint-disable react/no-array-index-key */
import React from 'react';

import { InstructionSet } from '../../../gql/prs/types';
import AppInstructions from './AppInstructions';
import { InstructionsContainer } from './PayoffStylings';
import PhoneInstructions from './PhoneInstructions';
import WebsiteInstructions from './WebsiteInstructions';

interface Props {
  lienholderName: string;
  phones: string[];
  phoneInstructions: InstructionSet[];
  webInstructions: InstructionSet[];
  appInstructions: InstructionSet[];
  website?: string;
}

const PayoffTemplate = ({
  lienholderName,
  phones,
  phoneInstructions,
  webInstructions,
  appInstructions,
  website,
}: Props) => {
  const hasPhones = phones?.length > 0;
  const hasApp = appInstructions?.length > 0;

  return (
    <InstructionsContainer>
      {hasApp && (
        <AppInstructions lienholderName={lienholderName} appInstructions={appInstructions} />
      )}
      {website && hasPhones ? (
        <>
          <WebsiteInstructions website={website} webInstructions={webInstructions} />
          <PhoneInstructions phones={phones} hasBoth phoneInstructions={phoneInstructions} />
        </>
      ) : (
        <>
          {hasPhones && (
            <PhoneInstructions
              phones={phones}
              hasBoth={false}
              phoneInstructions={phoneInstructions}
            />
          )}
          {website && <WebsiteInstructions website={website} webInstructions={webInstructions} />}
        </>
      )}
    </InstructionsContainer>
  );
};

export default PayoffTemplate;
