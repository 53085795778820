import { Box, Center, Checkbox, Flex, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import Loader from '../../../components/Loader';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { MKT_HOME } from '../../../constants/urls';
import {
  KbbOption,
  TransformType,
  useCalculateOptionsLazyQuery,
  useKbbValuesLazyQuery,
  useTemporaryInfoImportMutation,
  useTemporaryInfoUpdateMutation,
} from '../../../gql/generated/graphql';
import { useStep, useTempInfo } from '../../../hooks';
import { highLtvRatio } from '../../../utils/estimatedPayment';

export type KbbSelectedOption = {
  id: number;
  name: string;
  type: string;
  lendingOptionPrice: number;
  retailOptionPrice: number;
  removed: boolean;
  categoryGroup: string;
};

interface GetKbbValuesParams {
  vehicleId: number;
  trim: string;
  configToModify?: { optionId: number; action: string }[];
  startingVehicleOptions?: number[];
}

const EXTERIOR_COLOR_CATEGORY = 'Exterior Color';

const KbbOptions = () => {
  const [refresh, setRefresh] = useState<boolean>(false);
  const { info, infoLoading } = useTempInfo('network-only', refresh);
  const [vehicleOptionIds, setVehicleOptionIds] = useState<number[]>([]);
  const [initialVehicleOptionIds, setInitialVehicleOptionIds] = useState<number[]>([]);
  const [configLoading, setConfigLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const step = useStep();

  const [calculateOptions] = useCalculateOptionsLazyQuery({ fetchPolicy: 'network-only' });
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();
  const [importTemporaryInfo] = useTemporaryInfoImportMutation();
  const [getKbbValues] = useKbbValuesLazyQuery();

  const tempInfoData = info?.data;
  const allOptions = tempInfoData?.kbb_all_options;
  const currentSelectedOptions = tempInfoData?.kbb_selected_options;

  // checks if the temporary info is imported and if so, redirect to home
  const THIRTY_MINS = 1_800_000;
  useEffect(() => {
    const timeout = setTimeout(() => {
      setRefresh(!refresh);
    }, THIRTY_MINS);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (info && Object.keys(info).length === 0 && !infoLoading) {
      window.location.href = MKT_HOME;
    }
  }, [info, infoLoading]);

  useEffect(() => {
    if (!currentSelectedOptions) {
      return;
    }

    let options;
    if (typeof currentSelectedOptions === 'string') {
      options = JSON.parse(currentSelectedOptions);
    } else {
      options = currentSelectedOptions;
    }

    setVehicleOptionIds(
      (options as KbbSelectedOption[])?.filter((o) => !o.removed)?.map((o) => Number(o.id)) ?? [],
    );
  }, [currentSelectedOptions]);

  const alreadyRan = useRef(false);
  useEffect(() => {
    if (alreadyRan.current || !vehicleOptionIds.length) {
      return;
    }

    alreadyRan.current = true;
    setInitialVehicleOptionIds(vehicleOptionIds);
  }, [vehicleOptionIds]);

  useEffect(() => {
    if (!tempInfoData) {
      return;
    }

    const isHighLtvRatio = highLtvRatio({
      payoff: tempInfoData.vehicle_payoff,
      estimatedTaxesAndFees: tempInfoData.taxes_fees,
      kbbRetailValue: tempInfoData.retail_book_value,
    });

    if (!isHighLtvRatio) {
      step.moveNext();
      return;
    }

    updateTemporaryInfo({
      variables: {
        info: {
          id: info.id,
          data: {
            high_ltv_ratio: true,
          },
        },
      },
    });
  }, [tempInfoData]);

  if (infoLoading || !tempInfoData) {
    return <Loader />;
  }

  const sortedByGroup: { [x: string]: KbbOption[] } =
    allOptions?.reduce((prev: { [x: string]: KbbOption[] }, curr: { categoryGroup: string }) => {
      const copy = { ...prev };

      // Don't need to show Exterior Color because it was already selected in a previous step
      if (curr.categoryGroup === EXTERIOR_COLOR_CATEGORY) {
        return copy;
      }

      if (!prev[curr.categoryGroup]) {
        copy[curr.categoryGroup] = [];
      }

      copy[curr.categoryGroup].push(curr);
      return copy;
    }, {}) ?? {};

  const sortedByGroupReducedOptions: { [x: string]: KbbOption[] } =
    Object.entries(sortedByGroup)?.reduce(
      (prev: { [x: string]: KbbOption[] }, curr: [string, KbbOption[]]) => {
        const [categoryGroup, options] = curr;

        // Remove categories that all options are already selected
        const optionIds = options.map((option) => option.vehicleOptionId);
        if (optionIds.every((optionId) => initialVehicleOptionIds.includes(optionId as number))) {
          return prev;
        }

        const copy = { ...prev };

        copy[categoryGroup] = options;

        return copy;
      },
      {},
    ) ?? {};

  const getConfigurationOptions = async ({
    vehicleId,
    configToModify = [],
    startingVehicleOptions = [],
  }: GetKbbValuesParams) => {
    setConfigLoading(true);

    const response = await calculateOptions({
      variables: {
        // vehicleId can be undefined/null if the kbbValues query failed find the vehicle.
        // This is most common with brand new vehicles and Volvos
        vehicleId,
        allKbbVehicleOptions: allOptions,
        configToModify,
        startingVehicleOptions,
        vin: tempInfoData.vin as string,
        color: tempInfoData.color,
      },
    });

    const selectedOptionIds =
      response?.data?.calculateOptions?.map((o) => Number(o?.vehicleOptionId)) ?? [];

    setVehicleOptionIds(selectedOptionIds);

    setConfigLoading(false);
  };

  const changeConfiguration = (id: number, action: string) => {
    getConfigurationOptions({
      vehicleId: tempInfoData.kbb_vehicle_id as number,
      trim: tempInfoData.kbb_trim_name as string,
      configToModify: [{ optionId: id, action }],
      startingVehicleOptions: vehicleOptionIds,
    });
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    const { data: valuesData } = await getKbbValues({
      variables: {
        data: {
          allKbbVehicleOptions: allOptions,
          mileage: tempInfoData.mileage,
          vehicleId: tempInfoData.kbb_vehicle_id,
          vehicleOptionIds,
        },
      },
    });

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            kbb_selected_options: valuesData?.kbbValues?.kbbSelectedOptions,
            retail_book_value: valuesData?.kbbValues?.retail,
            kbb_retail_mileage_adjustment: valuesData?.kbbValues?.retailMileageAdjustment,
            kbb_retail_option_adjustment: valuesData?.kbbValues?.retailOptionAdjustment,
            book_value: valuesData?.kbbValues?.lending,
            kbb_lending_mileage_adjustment: valuesData?.kbbValues?.lendingMileageAdjustment,
            kbb_lending_option_adjustment: valuesData?.kbbValues?.lendingOptionAdjustment,
            kbb_valuation_date: valuesData?.kbbValues?.valuationDate,
          },
        },
      },
    });

    await importTemporaryInfo({
      variables: {
        id: info?.id ?? '',
        transformType: TransformType.AutoStructureBegin,
      },
    });

    setSubmitLoading(false);
    step.moveNext();
  };

  return (
    <Box>
      <Text textAlign="center" mb={8}>
        We want to make sure we properly assess your vehicle value. Select any of these vehicle
        options that apply to your car.
      </Text>
      {Object.keys(sortedByGroupReducedOptions).map((key) => (
        <Box mb="25px" key={key}>
          <Text fontWeight="bold" mb={2} fontSize="lg">
            {key}
          </Text>
          {sortedByGroupReducedOptions[key].map((o: KbbOption) => {
            const selected = vehicleOptionIds.includes(o?.vehicleOptionId ?? 0);

            return (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mb="5px"
                pb="5px"
                borderBottom="1px solid rgba(0,0,0,0.1)"
                key={o.vehicleOptionId}
              >
                <Text>{o.optionName}</Text>
                <Checkbox
                  m={0}
                  size="lg"
                  isDisabled={configLoading}
                  isChecked={selected}
                  onChange={() =>
                    changeConfiguration(
                      o?.vehicleOptionId ?? 0,
                      !selected ? 'selected' : 'deselected',
                    )
                  }
                />
              </Flex>
            );
          })}
        </Box>
      ))}
      <Center mt="30px">
        <PrimaryButton isLoading={submitLoading} onClick={handleSubmit}>
          LOOKS GOOD
        </PrimaryButton>
      </Center>
    </Box>
  );
};

export default KbbOptions;
