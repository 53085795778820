import { Box, Center, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { atom, useSetAtom } from 'jotai';

import { cobuyerFirstNameAtom } from '..';
import ButtonedRadioGroup from '../../../components/ButtonedRadioGroup';
import { useLEAccordionItemContext } from '../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import { Input, Label } from '../../../components/formComponents';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import {
  RelationToBuyerEnum,
  useTemporaryInfoUpdateMutation,
} from '../../../gql/generated/graphql';
import { useTempInfo } from '../../../hooks';
import {
  CobuyerPersonalInfoValues,
  cobuyerPersonalInfoInitialValues,
  cobuyerPersonalInfoValidationSchema,
} from '../utils';

export const cobuyerFirstAccordionContextAtom = atom(null);

const CobuyerPersonalInfo = () => {
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const { updateState, isExpanded, setOpenAccordionIndex, index } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const tempInfoData = info?.data;
  const setCobuyerFirstName = useSetAtom(cobuyerFirstNameAtom);

  const onSubmitForm = async (values: CobuyerPersonalInfoValues) => {
    updateState({ status: 'loading' });
    const firstName = values.cobuyer_first_name;

    const data = {
      cobuyer_first_name: firstName.trim(),
      cobuyer_last_name: values.cobuyer_last_name.trim(),
      cobuyer_phone_number: values.cobuyer_phone_number,
      cobuyer_email: values.cobuyer_email,
      cobuyer_relation_to_buyer: values.cobuyer_relation_to_buyer,
    };

    if (firstName.length < 15) {
      setCobuyerFirstName(firstName?.toUpperCase().trim());
    } else {
      setCobuyerFirstName(null);
    }

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    setOpenAccordionIndex(index + 1);
  };

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={cobuyerPersonalInfoInitialValues(tempInfoData)}
        validationSchema={cobuyerPersonalInfoValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmitForm}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => {
          const hasErrors = Object.values(errors).some((value) => value !== '');

          return (
            <Form>
              <Label>NAME</Label>
              <Flex
                mb={2}
                w="100%"
                flexDir={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
              >
                <Input
                  autoFocus
                  name="cobuyer_first_name"
                  placeholder="First"
                  _container={{ mr: { md: 1 }, mb: { base: 2, md: 0 } }}
                />
                <Input name="cobuyer_last_name" placeholder="Last" _container={{ ml: { md: 1 } }} />
              </Flex>
              <Input
                name="cobuyer_email"
                placeholder="Email"
                label="EMAIL ADDRESS"
                _container={{ mb: 2 }}
              />
              <Input
                label="Phone Number"
                name="cobuyer_phone_number"
                mask="(999) 999-9999"
                _container={{ mb: 4 }}
                placeholder="Phone Number"
                type="tel"
              />
              <Label mt={-1}>RELATION TO {tempInfoData?.first_name}</Label>
              <ButtonedRadioGroup
                columns={{ base: 2, md: 4 }}
                collapseOnMobile={false}
                onChange={(value) => setFieldValue('cobuyer_relation_to_buyer', value)}
                options={[
                  { label: 'SPOUSE', value: RelationToBuyerEnum.Spouse },
                  { label: 'CO-RESIDENT', value: RelationToBuyerEnum.ResidesWith },
                  { label: 'PARENT', value: RelationToBuyerEnum.Parent },
                  { label: 'OTHER', value: RelationToBuyerEnum.Other },
                ]}
                value={values?.cobuyer_relation_to_buyer}
                name="cobuyer_relation_to_buyer"
                defaultValue={RelationToBuyerEnum.Spouse}
              />
              <Center mt={6}>
                <PrimaryButton
                  mt="30px"
                  mb={{
                    base: '20px',
                    lg: '0',
                  }}
                  loading={isSubmitting}
                  type="submit"
                />
              </Center>
              {hasErrors && (
                <Text color="leaseEndRed" textAlign="center" mt={{ base: 0, lg: 4 }}>
                  Review fields above for errors.
                </Text>
              )}
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CobuyerPersonalInfo;
