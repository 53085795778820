import { Box, Center, useBreakpoint } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import { MKT_HOME } from '../../constants/urls';
import {
  CreateAffiliateTemporaryInfoMutation,
  ErrorCodeEnum,
  TemporaryData,
  useCreateAffiliateTemporaryInfoMutation,
  useTemporaryInfoLazyQuery,
} from '../../gql/generated/graphql';
import { CookieKeys, clearCookie, useCookie } from '../../hooks/useCookie';
import { resetLocalStorage } from '../../services/localStorage';
import { rudderanalytics } from '../../utils/rudderstack';
import AffiliateWidget from './AffiliateWidget';
import { AFFILIATE_PARAMS } from './utils';

const AffiliateEntry = () => {
  const [guid, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [showDecoder, setShowDecoder] = useState(false);
  const [tempInfoData, setTempInfoData] = useState<TemporaryData>();

  const { search } = useLocation();
  const device = useBreakpoint();

  const [getTempInfo] = useTemporaryInfoLazyQuery();
  const [createTempInfo] = useCreateAffiliateTemporaryInfoMutation({
    context: {
      isErrorHandled: true,
    },
  });

  const returnHome = () => {
    window.location.href = MKT_HOME;
  };

  const initialize = async (affiliateExternalId: string) => {
    if (guid) {
      const tempInfo = await getTempInfo({
        variables: { id: guid as string },
      });

      if (tempInfo.error?.graphQLErrors?.[0]?.extensions?.code === ErrorCodeEnum.ResetGuidError) {
        resetLocalStorage({ removeGuid: true });
        returnHome();
        return;
      }

      if (tempInfo.data?.temporaryInfo?.data?.affiliate_external_id === affiliateExternalId) {
        setTempInfoData(tempInfo.data.temporaryInfo.data);
        setShowDecoder(true);
        rudderanalytics.identify({ guid });
        return;
      }
    }

    const tempInfoMutation: CreateAffiliateTemporaryInfoMutation = await new Promise(
      (resolve, reject) =>
        createTempInfo({
          variables: {
            device_type: device,
            query_params: search,
          },
          onCompleted: resolve,
          onError: () => {
            returnHome();
            reject();
          },
        }),
    );

    const newGuid = tempInfoMutation.createAffiliateTemporaryInfo?.id as string;
    setGuid(newGuid);
    setTempInfoData(tempInfoMutation.createAffiliateTemporaryInfo?.data);
    clearCookie(CookieKeys.PREQUAL_PARAMS);
    rudderanalytics.identify({ guid: newGuid });

    setShowDecoder(true);
  };

  const isInitialized = useRef(false);
  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      const params = new URLSearchParams(search);
      const affiliateExternalId = params.get(AFFILIATE_PARAMS.AFFILIATE_EXTERNAL_ID);

      if (affiliateExternalId) {
        initialize(affiliateExternalId);
      } else {
        returnHome();
      }
    }
  }, [search]);

  return (
    <Box minH="85vh" mt="80px">
      {!showDecoder ? (
        <Loader />
      ) : (
        <Center mb={40} mt={{ base: '-40px', md: 'unset' }}>
          <AffiliateWidget affiliateTempInfoData={tempInfoData} />
        </Center>
      )}
    </Box>
  );
};

export default AffiliateEntry;
