import { Box, BoxProps, SkeletonText, Text, VStack } from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { SyntheticEvent, useState } from 'react';

import DynamicLinkContent from '../../components/DynamicLinkContent';
import Modal from '../../components/Modal';
import { Checkbox } from '../../components/formComponents';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../constants/flags';
import {
  TermBools,
  WISCONSIN_BUYER_LABEL,
  WISCONSIN_COBUYER_LABEL,
} from '../../constants/legalese';
import {
  ConsentAgreementTypeEnum,
  ConsentNameEnumType,
  useCurrentConsentAgreementTextQuery,
  useSaveConsentMutation,
} from '../../gql/generated/graphql';
import { useFlag, useTempInfo } from '../../hooks';
import { showWisconsinBuyerCheckbox, showWisconsinCobuyerCheckbox } from '../../utils/legalese';

const PREQUAL_CHECKBOX_VARIANTS = {
  showCheckbox: '0',
  hideCheckbox: '1',
};

const HARD_PULL_CONSENTS_VERSIONS = {
  showCheckbox: 1,
  hideCheckbox: 2,
};

interface Props extends BoxProps {
  onSubmit: () => void;
  variation?: 'modal' | 'page';
  isLoading?: boolean;
}

const NextStepsTerms = ({ onSubmit, variation = 'modal', isLoading, ...rest }: Props) => {
  const { info, infoLoading } = useTempInfo();
  const prequalCheckboxVariant = useFlag(LDFlags.PREQUAL_CONSENT_CHECKBOX);
  const isHardPull = !!info?.data?.is_hard_pull;
  const showHardPullCheckbox = prequalCheckboxVariant === PREQUAL_CHECKBOX_VARIANTS.showCheckbox;

  const ldClient = useLDClient();
  const [saveConsentMutation] = useSaveConsentMutation();
  const { data: termsCopy, loading: termsLoading } = useCurrentConsentAgreementTextQuery({
    variables: { agreementType: ConsentAgreementTypeEnum.TermsAndConditions },
  });
  const { data: hardPullConsentCopy, loading: hardPullLoading } =
    useCurrentConsentAgreementTextQuery({
      variables: {
        agreementType: ConsentAgreementTypeEnum.HardPull,
        version: showHardPullCheckbox
          ? HARD_PULL_CONSENTS_VERSIONS.showCheckbox
          : HARD_PULL_CONSENTS_VERSIONS.hideCheckbox,
      },
    });

  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [terms, setTerms] = useState<TermBools>({
    hardPullTermsAccepted: !showHardPullCheckbox,
    wisconsinBuyerTermsAccepted: false,
    wisconsinCobuyerTermsAccepted: false,
  });

  const tempInfoData = info?.data;

  const hasCobuyer = !!tempInfoData?.cobuyer_first_name;
  const wisconsinBuyerCheckbox = showWisconsinBuyerCheckbox(tempInfoData);
  const wisconsinCobuyerCheckbox = showWisconsinCobuyerCheckbox(tempInfoData, hasCobuyer);

  const submitDisabled =
    termsLoading ||
    hardPullLoading ||
    infoLoading ||
    !terms.hardPullTermsAccepted ||
    (wisconsinBuyerCheckbox ? !terms.wisconsinBuyerTermsAccepted : false) ||
    (wisconsinCobuyerCheckbox ? !terms.wisconsinCobuyerTermsAccepted : false);

  const submitLoading = isSubmitting || isLoading;

  const handleChange = (key: keyof TermBools) => {
    setTerms((prevTerms) => ({
      ...prevTerms,
      [key]: !terms[key],
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (!isHardPull) {
        await saveConsentMutation({
          variables: {
            type: ConsentNameEnumType.CreditScoreHardPull,
            version: showHardPullCheckbox
              ? HARD_PULL_CONSENTS_VERSIONS.showCheckbox
              : HARD_PULL_CONSENTS_VERSIONS.hideCheckbox,
          },
        });
        ldClient?.track('hard-pull-consent', {
          showHardPullCheckbox,
        });
      }
      await onSubmit();
    } finally {
      setIsSubmitting(false);
    }
  };

  const openTermsModal = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setTermsModalOpen(true);
  };

  if (
    !hardPullConsentCopy?.currentConsentAgreementText?.text ||
    !termsCopy?.currentConsentAgreementText?.text
  ) {
    return (
      <>
        {variation === 'page' && (
          <SkeletonText
            py={{ base: '15px', lg: '35px' }}
            width="100%"
            noOfLines={{ base: 30, md: 15 }}
          />
        )}
        <VStack
          alignItems="flex-start"
          mt={{ base: '50px', md: '80px' }}
          mb="10px"
          mx={0}
          {...rest}
        >
          <SkeletonText width="100%" noOfLines={{ base: 4, md: 2 }} />
        </VStack>
      </>
    );
  }

  return (
    <>
      {variation === 'page' && (
        <Box
          border="1px solid"
          borderColor="grayDarkBackground"
          p={{ base: '15px 20px', lg: '35px 40px' }}
          maxH="400px"
          overflowY="scroll"
          color="grayDark"
          lineHeight="24px"
          bg="white"
        >
          <Text mb="15px" textAlign="left">
            {termsCopy.currentConsentAgreementText.text}
          </Text>
        </Box>
      )}
      <VStack
        alignItems="flex-start"
        mt={{ base: '50px', md: '80px' }}
        mb="10px"
        mx={0}
        color="taupeGray"
        {...rest}
      >
        {!isHardPull && (
          <>
            {showHardPullCheckbox ? (
              <Checkbox
                fontSize={{ base: '12px', lg: '14px' }}
                mx="auto"
                checked={terms.hardPullTermsAccepted}
                onChange={() => handleChange('hardPullTermsAccepted')}
                isDisabled={termsLoading}
                label={
                  <DynamicLinkContent
                    content={hardPullConsentCopy?.currentConsentAgreementText?.text}
                    onDynamicContentClick={variation === 'modal' ? openTermsModal : undefined}
                  />
                }
              />
            ) : (
              <DynamicLinkContent
                content={hardPullConsentCopy?.currentConsentAgreementText?.text}
                onDynamicContentClick={variation === 'modal' ? openTermsModal : undefined}
              />
            )}
          </>
        )}
        {wisconsinBuyerCheckbox && (
          <Checkbox
            fontSize={{ base: '12px', lg: '14px' }}
            mx="auto"
            checked={terms.wisconsinBuyerTermsAccepted}
            onChange={() => handleChange('wisconsinBuyerTermsAccepted')}
            label={WISCONSIN_BUYER_LABEL}
          />
        )}
        {wisconsinCobuyerCheckbox && (
          <Checkbox
            fontSize={{ base: '12px', lg: '14px' }}
            mx="auto"
            checked={terms.wisconsinCobuyerTermsAccepted}
            onChange={() => handleChange('wisconsinCobuyerTermsAccepted')}
            label={WISCONSIN_COBUYER_LABEL}
          />
        )}
      </VStack>
      <ButtonWrapper>
        <PrimaryButton
          value="SUBMIT"
          isDisabled={submitDisabled}
          loading={submitLoading}
          onClick={handleSubmit}
        />
      </ButtonWrapper>
      <Modal
        title="Terms &amp; Conditions"
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      >
        <Text>{termsCopy?.currentConsentAgreementText?.text}</Text>
      </Modal>
    </>
  );
};

export default NextStepsTerms;
