import { LendingTreeLogo } from '../assets/Images';
import { AffiliateProviderSlug } from '../gql/generated/graphql';

export const affiliateProviderLogo = (slug?: AffiliateProviderSlug | null) => {
  switch (slug) {
    case AffiliateProviderSlug.LendingTree:
      return LendingTreeLogo;
    default:
      return undefined;
  }
};
