import { Button, ButtonProps, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FaChevronLeft } from 'react-icons/fa6';

import { BackOptions } from '../../../experiments/itineraries';
import { useStep } from '../../../hooks/useStep';

interface BackButtonProps extends ButtonProps {
  options?: BackOptions;
  loading?: boolean;
  onClick?: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({
  options,
  loading,
  children,
  onClick,
  ...props
}) => {
  const step = useStep();
  const onClickProp = onClick || (() => step.movePrevious(options));

  return (
    <Button
      isLoading={loading}
      onClick={onClickProp}
      variant={{ base: 'secondaryLink', md: 'secondary' }}
      position={{ base: 'absolute', md: 'unset' }}
      top="-70px"
      left="-20px"
      zIndex={1000}
      {...props}
    >
      <>
        {children ? (
          { children }
        ) : (
          <>
            <Text as="span" display={{ base: 'none', md: 'block' }}>
              Back
            </Text>
            <Icon boxSize={7} as={FaChevronLeft} display={{ base: 'block', md: 'none' }} />
          </>
        )}
      </>
    </Button>
  );
};

export default BackButton;
